export const GET_PRODUCTS_BEGIN = "GET_PRODUCTS_BEGIN"; // loading
export const GET_PRODUCTS_SUCCESS = "GET_PRODUCTS_SUCCESS"; // get all the products
export const GET_PRODUCTS_ERROR = "GET_PRODUCTS_ERROR"; // if error
export const UPDATE_FILTERS = "UPDATE_FILTERS"; //update fetch with input values
export const UPDATE_FIELD = "UPDATE_FIELD"; //update fetch with input values
export const INCREASE_STEP = "INCREASE_STEP"; // increase form step
export const DECREASE_STEP = "DECREASE_STEP"; //decrease form step
export const HANDLE_UNDEFINED = "HANDLE_UNDEFINED";
export const UPDATE_VEHICLE_FIELD = "UPDATE_VEHICLE_FIELD";
export const UPDATE_CUSTOMER_FROM_URL = "UPDATE_CUSTOMER_FROM_URL";
export const ACCOUNT_NUMBER_ERROR = "ACCOUNT_NUMBER_ERROR";
export const GET_LANDING_PRODUCTS_ERROR = "GET_LANDING_PRODUCTS_ERROR";
export const SET_SORT_OPTION = "SET_SORT_OPTION";
export const SET_ORDER_OPTION = "SET_ORDER_OPTION";
export const ACCOUNT_NUMBER_VALID = "ACCOUNT_NUMBER_VALID";
export const SORT_CODE_ERROR = "SORT_CODE_ERROR";
export const UPDATE_LANDING_PRODUCTS = "UPDATE_LANDING_PRODUCTS";
export const GET_LANDING_PRODUCTS = "GET_LANDING_PRODUCTS";
export const RESET_LANDING_PRODUCTS = "RESET_LANDING_PRODUCTS";
export const UPDATE_LANDING_FILTERS = "UPDATE_LANDING_FILTERS";
export const SET_QUESTIONS_CHECKED = "SET_QUESTIONS_CHECKED";
export const SORT_CODE_VALID = "SORT_CODE_VALID";
export const GET_ORIGIN = "GET_ORIGIN";
export const SEARCH = "SEARCH";
export const SEARCH_REG_NO_IN_PROGRESS = "SEARCH_REGNO_IN_PROGRESS";
export const DELETE_STRIPE_CLIENT_SECRET = "DELETE_STRIPE_CLIENT_SECRET";
export const INCREASE_CHECKED_QUESTIONS = "INCREASE_CHECKED_QUESTIONS";
export const DECREASE_CHECKED_QUESTIONS = "DECREASE_CHECKED_QUESTIONS"
export const ADD_TO_CART = "ADD_TO_CART";
export const REMOVE_CART_ITEM = "REMOVE_CART_ITEM";
export const ADD_PRODUCT_SIDEBAR = "ADD_PRODUCT_SIDEBAR";
export const CLEAR_CART = "CLEAR_CART";
export const DISPLAY_FIRST = "DISPLAY_FIRST";
export const COUNT_CART_TOTALS = "COUNT_CART_TOTALS";
export const CHANGE_CARD_INDEX = "CHANGE_CARD_INDEX";
export const GET_QUOTE_ID = "GET_QUOTE_ID";
export const SAVE_TO_LOCAL_STORAGE = "SAVE_TO_LOCAL_STORAGE";
export const FILTER_PRODUCT_SUCCESS = "FILTER_PRODUCT_SUCCESS";
export const GET_PURCHASE_DATE = "GET_PURCHASE_DATE";
export const GET_REGISTRATION_DATE = "GET_REGISTRATION_DATE";
export const GET_QUESTIONS_SUCCESS = "GET_QUESTIONS_SUCCESS";
export const GET_QUESTIONS_BEGIN = "GET_QUESTIONS_BEGIN";
export const COMPLETE_ORDER_BEGIN = "COMPLETE_ORDER_BEGIN";
export const GET_PURCHASE_CODE = "GET_PURCHASE_CODE";
export const SET_FETCHED_VEHICLE_DETAILS = "SET_FETCHED_VEHICLE_DETAILS";
export const SET_SELECTED_PRODUCTS = "SET_SELECTED_PRODUCTS";
export const CHANGE_SELECTED_PRODUCTS_DATE = "CHANGE_SELECTED_PRODUCTS_DATE";
export const SET_TYPE_OF_PAYMENT = "SET_TYPE_OF_PAYMENT";
export const SET_BIRTHDAY = "SET_BIRTHDAY";
export const SAVE_TOKEN = "SAVE_TOKEN";
export const REFRESH_IDS = "REFRESH_IDS";
export const DELETE_EXISTING_STRIPE_DATA = "DELETE_EXISTING_STRIPE_DATA";
export const RETURN_TO_PREVIOUS_STATE_FROM_PAYMENT =
  "RETURN_TO_PREVIOUS_STATE_FROM_PAYMENT";
export const DISABLE_REACT_DATE_PICKER = "DISABLE_REACT_DATE_PICKER";
export const CHECK_QUESTION = "CHECK_QUESTION";
export const UNCHECK_ALL_QUESTIONS = "UNCHECK_ALL_QUESTIONS";
export const SEARCH_POSTCODE_IN_PROGRESS = "SEARCH_POSTCODE_IN_PROGRESS";
export const SET_ADDRESS_DETAILS = "SET_ADDRESS_DETAILS";
export const UPDATE_ADDRESS = "UPDATE_ADDRESS";
export const CHOOSECO2 = "CHOOSECO2";
export const UPDATE_PERSONAL_FUNDING_DETAILS =
  "UPDATE_PERSONAL_FUNDING_DETAILS";
export const UPDATE_HIRE_AND_REWARD_QUESTIONS =
  "UPDATE_HIRE_AND_REWARD_QUESTIONS";
export const CHECK_BANK_DETAILS = "CHECK_BANK_DETAILS";
export const START_CHECK_VALIDATION = "START_CHECK_VALIDATION";
export const SET_PAYMENT_DAY = "SET_PAYMENT_DAY";
export const FAILED_BANK_VALIDATION_REQUEST = "FAILED_BANK_VALIDATION_REQUEST";
export const SET_FIN_IDD_RESULT = "SET_FIN_IDD_RESULT";
export const SET_FIN_COMPLAINTS = "SET_FIN_COMPLAINTS";
export const SET_FIN_CANCELLATION = "SET_FIN_CANCELLATION";

//Wedding actions

export const SET_EVENT_DETAILS = "SET_EVENT_DETAILS";
export const QUOTE_DETAILS_ERROR = "QUOTE_DETAILS_ERROR";

export const SET_DATE = "SET_DATE";
export const INCREASE_WEDDING_STEP = "INCREASE_WEDDING_STEP";
export const DECREASE_WEDDING_STEP = "DECREASE_WEDDING_STEP";
export const FIN_IDD_LOADING = "FIN_IDD_LOADING";
export const FIN_IDD_ERROR = "FIN_IDD_ERROR";
export const GET_WEDDING_PRODUCTS = "GET_WEDDING_PRODUCTS";
export const GET_WEDDING_PRODUCTS_LOADING = "GET_WEDDING_PRODUCTS_LOADING";
export const GET_WEDDING_PRODUCTS_ERROR = "GET_WEDDING_PRODUCTS_ERROR";
export const UPDATE_ADDITIONAL_OPTIONS = "UPDATE_ADDITIONAL_OPTIONS";
export const UPDATE_PERSONAL_DETAILS = "UPDATE_PERSONAL_DETAILS";
export const UPDATE_EVENT_DETAILS = "UPDATE_EVENT_DETAILS";
export const UPDATE_CART = "UPDATE_CART";
export const UPDATE_PROGRESS_BAR_INDEX = "UPDATE_PROGRESS_BAR_INDEX";
export const RETURN_TO_PRODUCTS = "RETURN_TO_PRODUCTS";

//Init basket

export const SET_QUERY_PARAMS = "SET_QUERY_PARAMS";
export const START_GET_QUOTE_DETAILS_LOADING =
  "START_GET_QUOTE_DETAILS_LOADING";
export const SET_QUOTE_DETAILS = "SET_QUOTE_DETAILS";
export const CHANGE_CHECKOUT_STEP = "CHANGE_CHECKOUT_STEP";
export const UPDATE_INIT_VALUES = "UPDATE_INIT_VALUES";
export const UPDATE_REDIRECT_FLAG = "UPDATE_REDIRECT_FLAG";
export const GET_PRODUCTS_FROM_SESSION = "GET_PRODUCTS_FROM_SESSION"; 
export const UPDATE_STEP = "UPDATE_STEP"; 
export const COMPLETE_QUOTE_ERROR = "COMPLETE_QUOTE_ERROR"; 
export const SET_INIT_BASKET_QUERY_PARAMS = "SET_INIT_BASKET_QUERY_PARAMS"; 


//checkout modal

export const OPEN_MODAL = "OPEN_MODAL"; 
export const CLOSE_MODAL = "CLOSE_MODAL"; 


//init basket for vehicle
export const INIT_BASKET_REDIRECT = "INIT_BASKET_REDIRECT"; 
export const CHANGE_STEP_VALUE = "CHANGE_STEP_VALUE"; 


//vehicle checkout v2
export const SEARCH_REGNO_ERROR = "SEARCH_REGNO_ERROR"; 
export const DESELECT_PRODUCT = "DESELECT_PRODUCT"; 
export const UPDATE_IDS_ARRAY = "UPDATE_IDS_ARRAY"; 
export const UPDATE_QUOTE_ID = "UPDATE_QUOTE_ID"; 
export const UPDATE_REGISTRATION_DATE = "UPDATE_REGISTRATION_DATE"; 


