import React, { useContext, useEffect, useReducer } from "react";
import reducer from "../reducers/products-reducer";
import FetchResponse from "../utils/products.json";
import scrollTo from "gatsby-plugin-smoothscroll";
import moment from "moment";
import { navigate } from "gatsby";
import { useParams } from "react-router-dom";

import {
  GET_PRODUCTS_BEGIN,
  GET_PRODUCTS_SUCCESS,
  GET_PRODUCTS_ERROR,
  UPDATE_FILTERS,
  START_CHECK_VALIDATION,
  FAILED_BANK_VALIDATION_REQUEST,
  CHECK_BANK_DETAILS,
  ACCOUNT_NUMBER_ERROR,
  SORT_CODE_ERROR,
  ACCOUNT_NUMBER_VALID,
  SORT_CODE_VALID,
  SEARCH,
  GET_QUOTE_ID,
  FILTER_PRODUCT_SUCCESS,
  SET_DATE,
  INCREASE_WEDDING_STEP,
  SET_ADDRESS_DETAILS,
  SEARCH_POSTCODE_IN_PROGRESS,
  SET_BIRTHDAY,
  UPDATE_PERSONAL_FUNDING_DETAILS,
  FIN_IDD_LOADING,
  FIN_IDD_ERROR,
  SET_FIN_IDD_RESULT,
  DECREASE_WEDDING_STEP,
  GET_WEDDING_PRODUCTS,
  SET_TYPE_OF_PAYMENT,
  GET_WEDDING_PRODUCTS_LOADING,
  GET_WEDDING_PRODUCTS_ERROR,
  ADD_TO_CART,
  CLEAR_CART,
  SET_ADDITIONAL_OPTIONS,
  UPDATE_PERSONAL_DETAILS,
  UPDATE_ADDITIONAL_OPTIONS,
  SET_FIN_CANCELLATION,
  SET_FIN_COMPLAINTS,
  UPDATE_EVENT_DETAILS,
  UPDATE_CART,
  UPDATE_PROGRESS_BAR_INDEX,
  SET_QUERY_PARAMS,
  START_GET_QUOTE_DETAILS_LOADING,
  GET_QUESTIONS_BEGIN,
  GET_QUESTIONS_SUCCESS,
  SET_QUOTE_DETAILS,
  CHANGE_CHECKOUT_STEP,
  DELETE_STRIPE_CLIENT_SECRET,
  UPDATE_INIT_VALUES,
  UPDATE_REDIRECT_FLAG,
  GET_PRODUCTS_FROM_SESSION,
  UPDATE_STEP,
  GET_PURCHASE_CODE,
  COMPLETE_ORDER_BEGIN,
  DELETE_EXISTING_STRIPE_DATA,
  COMPLETE_QUOTE_ERROR,
  RETURN_TO_PRODUCTS,
  QUOTE_DETAILS_ERROR,
  SET_INIT_BASKET_QUERY_PARAMS
} from "../actions/actions";
import { MyTheme } from "../utils/informations";

// const getLocalStorage = async () => {
//   let products =  sessionStorage.getItem('insurances')
//   if (products) {
//     // console.log('products', JSON.parse(sessionStorage.getItem('insurances')))
//     return  (await JSON.parse(sessionStorage.getItem('insurances')))
//   } else {
//     return []
//   }
// }

//group Finance Gap an Return to Invoice

const OriginMapper = {
  MBI: "list1",
  "Alloy Wheel": "list2",
  "Cosmetic Repair": "list3",
  Tyre: "list4",
  "Finance Gap": "list5",
  "Return to Invoice": "list5",
};

const dataGroupedByOriginMapper = {};

const otherItemsListName = "others";

FetchResponse.forEach((item) => {
  const { Origin } = item;
  const listName =
    Origin in OriginMapper ? OriginMapper[Origin] : otherItemsListName;

  if (
    listName in dataGroupedByOriginMapper &&
    dataGroupedByOriginMapper[listName]
  ) {
    dataGroupedByOriginMapper[listName].push(item);
  } else {
    dataGroupedByOriginMapper[listName] = [item];
  }
});

let formStep;
let storedPersonalDetails;
let storedAdditionalOptions;
let storedWeddingDetails;
let storedWeddingProducts;
let PurchaseCode;
let formData;
let id;
let weddingCart = [];
let quoteId;
let premiumFundingDetailsInitBasket = {};
let weddingQuote;
let storedIdd;
let complaints;
let cancellation;

if (
  typeof window !== "undefined" &&
  window.location.pathname === "/init-basket"
) {
  const queryParams = new URLSearchParams(window.location.search);
  id = queryParams.get("id");
  quoteId = queryParams.get("quoteId");

}
if (
  typeof window !== "undefined" &&
  window.sessionStorage.getItem("currentStep")
) {
  formStep = JSON.parse(sessionStorage.getItem("currentStep"));
}

if (
  typeof window !== "undefined" &&
  window.sessionStorage.getItem("weddingCart")
) {
  weddingCart = JSON.parse(sessionStorage.getItem("weddingCart"));
}
if (
  typeof window !== "undefined" &&
  window.sessionStorage.getItem("weddingQuote")
) {
  weddingQuote = sessionStorage.getItem("weddingQuote");
}
if (
  typeof window !== "undefined" &&
  window.sessionStorage.getItem("weddingPersonalDetails")
) {
  storedPersonalDetails = JSON.parse(
    sessionStorage.getItem("weddingPersonalDetails")
  );
}
if (
  typeof window !== "undefined" &&
  window.sessionStorage.getItem("additionalOption")
) {
  storedAdditionalOptions = JSON.parse(
    sessionStorage.getItem("additionalOption")
  );
}
if (
  typeof window !== "undefined" &&
  window.sessionStorage.getItem("weddingDetails")
) {
  storedWeddingDetails = JSON.parse(sessionStorage.getItem("weddingDetails"));
}

if (
  typeof window !== "undefined" &&
  window.sessionStorage.getItem("weddingIdd")
) {
  storedIdd = sessionStorage.getItem("weddingIdd");
}
if (
  typeof window !== "undefined" &&
  window.sessionStorage.getItem("weddingProducts")
) {
  storedWeddingProducts = JSON.parse(sessionStorage.getItem("weddingProducts"));
}

let storedData;
if (
  typeof window !== "undefined" &&
  window.sessionStorage.getItem("quoteDetails")
) {
  storedData = JSON.parse(sessionStorage.getItem("quoteDetails"));
}
let redirectFlag;

if (
  typeof window !== "undefined" &&
  window.sessionStorage.getItem("redirectFlag")
) {
  redirectFlag = JSON.parse(sessionStorage.getItem("redirectFlag"));
}

if (
  typeof window !== "undefined" &&
  window.sessionStorage.getItem("weddingPurchaseCode")
) {
  PurchaseCode = sessionStorage.getItem("weddingPurchaseCode");
}
if (
  typeof window !== "undefined" &&
  window.sessionStorage.getItem("premiumFundingDetailsInitBasket")
) {
  premiumFundingDetailsInitBasket = JSON.parse(sessionStorage.getItem("premiumFundingDetailsInitBasket"));
}
if (
  typeof window !== "undefined" &&
  window.sessionStorage.getItem("formData")
) {
  formData = sessionStorage.getItem("formData");
}


const initialState = {
  products_loading: false,
  products_error: false,
  products: FetchResponse, //list of products from api fetch
  filters: {
    mileage: formData?.mileage ? formData?.mileage : "",
    vehicleValue: formData?.vehicleValue ? formData?.vehicleValue : "",
    regNo: formData?.regNo ? formData?.regNo : "",
    category: "MBI",
  },
  search: false,
  quoteID: "",
  successResponse: false,

  //events state

  step: formStep ? formStep : 0,
  isStepChanged: false,
  weddingDetails: {
    ceremonyType: storedWeddingDetails ? storedWeddingDetails.ceremonyType : "",
    location: storedWeddingDetails ? storedWeddingDetails.location : true,
    weddingDate: storedWeddingDetails
      ? new Date(storedWeddingDetails.weddingDate)
      : new Date(),
    reception: storedWeddingDetails ? storedWeddingDetails.reception : false,
    ceremonyAndReception: storedWeddingDetails
      ? storedWeddingDetails.ceremonyAndReception
      : false,
    receptionDate: storedWeddingDetails
      ? new Date(storedWeddingDetails.receptionDate)
      : new Date(),
    totalCost: storedWeddingDetails ? storedWeddingDetails.totalCost : "",
    publicLiabilityCover: storedWeddingDetails
      ? storedWeddingDetails.publicLiabilityCover
      : false,
    publicLiabilityLevel: storedWeddingDetails
      ? storedWeddingDetails.publicLiabilityLevel
      : "",
    monetaryLiabilityCover: storedWeddingDetails
      ? storedWeddingDetails.monetaryLiabilityCover
      : "",
    guestsNumber: storedWeddingDetails ? storedWeddingDetails.guestsNumber : "",
    marqueeCover: storedWeddingDetails ? storedWeddingDetails.marqueeCover : false,
    marqueeCoverDaysNumber: storedWeddingDetails
      ? storedWeddingDetails.marqueeCoverDaysNumber
      : "",
    marqueeCoverAmount: storedWeddingDetails
      ? storedWeddingDetails.marqueeCoverAmount
      : "",
    ceremonialSwords: storedWeddingDetails
      ? storedWeddingDetails.ceremonialSwords
      : false,
    covidCover: storedWeddingDetails
      ? storedWeddingDetails.covidCover
      : false,
  },
  personalDetails: {
    firstCeremonyParticipantForname: storedPersonalDetails
      ? storedPersonalDetails.firstCeremonyParticipantForname
      : "",
    firstCeremonyParticipantSurname: storedPersonalDetails
      ? storedPersonalDetails.firstCeremonyParticipantSurname
      : "",
    firstCeremonyParticipantDob: storedPersonalDetails
      ? new Date(storedPersonalDetails.firstCeremonyParticipantDob)
      : new Date(),
    firstParticipantUkResidentAndRegistered: storedPersonalDetails
      ? storedPersonalDetails.firstParticipantUkResidentAndRegistered
      : true,
    secondCeremonyParticipantsFirstname: storedPersonalDetails
      ? storedPersonalDetails.secondCeremonyParticipantsFirstname
      : "",
    secondCeremonyParticipantsSurname: storedPersonalDetails
      ? storedPersonalDetails.secondCeremonyParticipantsSurname
      : "",
    secondCeremonyParticipantsDob: storedPersonalDetails
      ? new Date(storedPersonalDetails.secondCeremonyParticipantsDob)
      : new Date(),
    secondParticipantUkResidentAndRegistered: storedPersonalDetails
      ? storedPersonalDetails.secondParticipantUkResidentAndRegistered
      : true,
    purchaser: storedPersonalDetails ? storedPersonalDetails.purchaser : false,
    relationWithParticipants: storedPersonalDetails
      ? storedPersonalDetails.relationWithParticipants
      : "",
    purchaserFirstname: storedPersonalDetails
      ? storedPersonalDetails.purchaserFirstname
      : "",
    purchaserSurname: storedPersonalDetails
      ? storedPersonalDetails.purchaserSurname
      : "",
    purchaserDob: storedPersonalDetails
      ? new Date(storedPersonalDetails.purchaserDob)
      : new Date(),
    houseNumber: storedPersonalDetails ? storedPersonalDetails.houseNumber : "",
    postcode: storedPersonalDetails ? storedPersonalDetails.postcode : "",
    fullAddress: storedPersonalDetails ? storedPersonalDetails.fullAddress : "",
    address: storedPersonalDetails ? storedPersonalDetails.address : "",
    telephoneNumber: storedPersonalDetails
      ? storedPersonalDetails.telephoneNumber
      : "",
    emailAddress: storedPersonalDetails
      ? storedPersonalDetails.emailAddress
      : "",
  },
  additionalOptions: {
    marquee: storedAdditionalOptions ? storedAdditionalOptions.marquee : "",
    sword: storedAdditionalOptions ? storedAdditionalOptions : "",
  },
  addressArray: [],
  searchPostcodeInProgress: false,
  accountNoError: false,
  sortCodeError: false,
  finIddLoading: false,
  finIddResult: storedIdd ? storedIdd : "",
  finIddError: false,
  quoteIDEvents: weddingQuote ? weddingQuote : "",
  weddingProductsLoading: false,
  stripeClientSecret: '',
  weddingProducts: storedWeddingProducts ? storedWeddingProducts : [],
  weddingProductsError: false,
  cart: weddingCart ? weddingCart : "",
  selectedProductCodeTemplate: "",
  // hash: [,
  //   "/wedding-checkout",
  //   "#event-details",
  //   "#products",
  //   "#legal",
  //   "#payment",
  // ],
  quoteDetails: {
    productType: storedData?.ProductTypes ? storedData?.ProductTypes : "",
    quoteId: storedData?.quoteId ? storedData?.quoteId : "",
    firstname: storedData?.FirstName ? storedData?.FirstName : "",
    surname: storedData?.Surname ? storedData?.Surname : "",
    address: storedData?.Address1 ? storedData?.Address1 : "",
    mobile: storedData?.Mobile ? storedData?.Mobile : "",
    town: storedData?.Town ? storedData?.Town : "",
    postcode: storedData?.Postcode ? storedData?.Postcode : "",
    email: storedData?.Email ? storedData?.Email : "",
    dob: storedData?.DateOfBirth ? storedData?.DateOfBirth : "",
    eventDate: storedData?.EventDate ? storedData?.EventDate : "",
    products: storedData?.products ? storedData?.products : [],
    code: storedData?.cod ? storedData?.cod : "test",
  },

  ids: id,
  quoteIdInitBasket: quoteId,
  isQuestionsLoading: false,
  directDebitDetails: {
    firstname: "",
    surname: "",
    sortCode: "",
    accountNo: "",
    bankName: "",
    bankPostcode: "",
    bankAddress1: "",
    bankAddress2: "",
    bankTown: "",
    preferredPaymentDateUnformatted: new Date(),
    preferredPaymentDate: moment(new Date()).format("DD/MM/YYYY"),
  },
  dateOfBirthUnformatted: new Date(),
  premiumFundingDetails: premiumFundingDetailsInitBasket?.premiumFundingDetails ? premiumFundingDetailsInitBasket.premiumFundingDetails : {},
  questions: [],
  needsAndDemandsCode: "",
  quoteIdError: false,
  getQuoteDetailsLoading: true,
  typeOfPayment: 'fullPayment',
  testQuoteDetails: {},
  isRedirected: redirectFlag ? redirectFlag : false,

  finIDDfetchResult: storedIdd ? storedIdd : "",
  finComplaints: complaints ? complaints : "",
  finCancellation: cancellation ? cancellation : "",

  //stripe payment

  stripeTokenId: '',
  stripeStatusCode: '',
  purchaseLoading: '',
  purchaseCode: '',
  completeQuoteError: false,

};

const ProductsContext = React.createContext();

export const ProductsProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);
  let { id, quoteId } = useParams();
  //  const [id, setNum] = useQueryParam("id", StringParam);
  // const [quoteId, setFoo] = useQueryParam("quoteId", StringParam);
  // console.log(id, quoteId)

  //   useEffect(() => {
  //     if (
  //   typeof window !== "undefined" &&
  //   window.location.pathname === "/init-basket"
  // ) {
  //     dispatch({type:SET_INIT_BASKET_QUERY_PARAMS, payload:{ id, quoteId}})
  // }
  //   }, [id, quoteId])

  const updateFilters = (e) => {
    let name = e.target.name;
    let value = e.target.value;

    dispatch({ type: UPDATE_FILTERS, payload: { value, name } });
  };

  const updatePersonalDetails = (e) => {

    let name = e.target.name;
    let value = e.target.value;

    dispatch({ type: UPDATE_PERSONAL_DETAILS, payload: { value, name } });
  };

  // const finIdd = async () => {
  //   let myHeaders = new Headers();
  //   myHeaders.append("Content-Type", "application/json");
  //   myHeaders.append("x-api-key", process.env.GATSBY_FIN_IDD_KEY);

  //   let raw = JSON.stringify({
  //     OrganisationLogo:
  //      MyTheme.externalLogo,
  //     OrganisationLogoAlt: MyTheme.pageTitle,
  //     AddressLine_1: "V12 b Merlin Park,",
  //     AddressLine_2: "Ringtail Road,",
  //     AddressLine_3: "Burscough, L40 8JY",
  //     FCANumber: "732530",
  //   });

  //   let requestOptions = {
  //     method: "POST",
  //     headers: myHeaders,
  //     body: raw,
  //     redirect: "follow",
  //   };

  //   try {
  //     const response = await fetch(
  //       process.env.GATSBY_FIN_API_IDD,
  //       requestOptions
  //     );
  //     const data = await response.text();

  //     dispatch({ type: SET_FIN_IDD_RESULT, payload: data });
  //   } catch (error) {
  //     console.log(error);
  //   }
  // };

  const complaintsProcedure = async () => {
    let myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("x-api-key", process.env.GATSBY_FIN_DEFAULT_KEY);

    let raw = JSON.stringify({
      OrganisationLogo:
        MyTheme.externalLogo,
      OrganisationLogoAlt: MyTheme.pageTitle,
      AddressLine_1: "V12 b Merlin Park,",
      AddressLine_2: "Ringtail Road,",
      AddressLine_3: "Burscough, L40 8JY",
      FCANumber: "732530",
    });

    let requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    try {
      const response = await fetch(
        process.env.GATSBY_FIN_COMPLAINTS_API,
        requestOptions
      );
      const data = await response.text();

      dispatch({ type: SET_FIN_COMPLAINTS, payload: data });
    } catch (error) {
      console.log(error);
    }
  };
  const cancellationAndClaimProcess = async () => {
    let myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("x-api-key", process.env.GATSBY_FIN_DEFAULT_KEY);

    let raw = JSON.stringify({
      OrganisationLogo:
        MyTheme.externalLogo,
      OrganisationLogoAlt: MyTheme.pageTitle,
      AddressLine_1: "V12 b Merlin Park,",
      AddressLine_2: "Ringtail Road,",
      AddressLine_3: "Burscough, L40 8JY",
      FCANumber: "732530",
    });

    let requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    try {
      const response = await fetch(
        process.env.GATSBY_FIN_CANCELLATION_API,
        requestOptions
      );
      const data = await response.text();

      dispatch({ type: SET_FIN_CANCELLATION, payload: data });
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    complaintsProcedure();
    cancellationAndClaimProcess();

    //   if(typeof window !== 'undefined' && window.sessionStorage.getItem('purchaseCode')) {
    //   PurchaseCode = sessionStorage.getItem('purchaseCode');
    // }

    // sessionStorage.setItem('purchaseCode', state.purchaseCode)
  }, []);

  const updateEventDetails = (e) => {

    let name = e.target.name;
    let value;

    //console.log(e.target.type)

    if (e.target.type === "checkbox") {

      value = e.target.checked;
    }
    else {
      value = e.target.value;
    }

    //console.log(name + "........" + value)

    dispatch({ type: UPDATE_EVENT_DETAILS, payload: { value, name } });
  };


  const updateEventToggle = (e) => {

    let name = e.target.name;
    let value;

    value = e.target.checked;

    //console.log(value)

    // console.log(name)
    // console.log(checked)

    dispatch({ type: UPDATE_PERSONAL_DETAILS, payload: { value, name } });

  };

  const updateAdditionalOptions = (e) => {
    let name = e.target.name;
    let value = e.target.value;

    dispatch({ type: UPDATE_ADDITIONAL_OPTIONS, payload: { value, name } });
    // dispatch({type:UPDATE_CART})
  };

  if (typeof window !== "undefined") {
    sessionStorage.setItem("getQuote", false);
  }

  const fetchFiltredProducts = async () => {
    dispatch({ type: GET_PRODUCTS_BEGIN });

    let registrationNumber = state.filters.regNo;
    let vehicleValue = state.filters.vehicleValue;
    let mileage = state.filters.mileage;

    let myHeaders = new Headers();
    myHeaders.append("Cookie", "ASP.NET_SessionId=5tnbx0wsv0qzqaf4akfajpps");

    let formdata = new FormData();

    formdata.append(
      "data",
      `{\n'IgnoreFilters':'false',\n'ProviderId':'${process.env.GATSBY_PROVIDER_ID}',\n'RegNo':'${registrationNumber}',\n'Mileage': '${mileage}',\n'Price': '${vehicleValue}',\n'FirstName': 'Test',\n'Surname': 'Surname',\n'Address1': 'test 1234',\n'Email': 'test333@phaeria.com'\n}`
    );

    let requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: formdata,
      redirect: "follow",
    };

    const response = await fetch(
      `${process.env.GATSBY_ENTER_VOUCHER}ExternalQuote/GetQuote`,
      requestOptions
    );
    const data = await response.json();
    const { products, quoteId, code, status } = data;

    if(( code && code === "Success")||(status && !code & status === "Success")) {
      dispatch({ type: SEARCH });

      dispatch({ type: GET_PRODUCTS_SUCCESS, payload: products });
      dispatch({ type: GET_QUOTE_ID, payload: quoteId });
      dispatch({ type: FILTER_PRODUCT_SUCCESS });
      scrollTo(`#products`);
      localStorage.setItem("getQuote", true);
    }
    else 
    {
      localStorage.setItem("getQuote", false);
      dispatch({ type: GET_PRODUCTS_ERROR });
    }
  };

  //Wedding-checkout procedures

  const increaseStep = () => {
    dispatch({ type: INCREASE_WEDDING_STEP });
  };
  const decreaseStep = () => {
    dispatch({type: DELETE_STRIPE_CLIENT_SECRET});
    dispatch({ type: DECREASE_WEDDING_STEP });
  };

  useEffect(() => {
    if (typeof window !== "undefined") {
      sessionStorage.setItem("currentStep", JSON.stringify(state.step));
    }
  }, [state.step]);

  useEffect(() => {
    if (typeof window !== "undefined") {
      sessionStorage.setItem("weddingCart", JSON.stringify(state.cart));
    }
  }, [state.cart]);


  useEffect(() => {
    if (
      typeof window !== "undefined" &&
      window.location.pathname === "/wedding-checkout"
    ) {
      sessionStorage.setItem("getQuote", false);
    }
    window.addEventListener("popstate", decreaseStep);
    return () => window.removeEventListener("popstate", decreaseStep);
  });


  useEffect(() => {

    if (typeof window !== "undefined") {
      sessionStorage.setItem("weddingDetails", JSON.stringify(state.weddingDetails))
    }
  }, [state.weddingDetails]);

  useEffect(() => {

    if (typeof window !== "undefined") {
      sessionStorage.setItem("weddingPersonalDetails", JSON.stringify(state.personalDetails))
    }
  }, [state.personalDetails]);

  useEffect(() => {

    if (typeof window !== "undefined") {
      sessionStorage.setItem("additionalOptions", JSON.stringify(state.additionalOptions))
    }
  }, [state.additionalOptions]);



  useEffect(() => {
    window.localStorage.setItem(
      "personalDetailsWedding",
      JSON.stringify(state.personalDetails)
    );
  }, [state.personalDetails]);

  const getDate = (date, id) => {
    dispatch({ type: SET_DATE, payload: { date, id } });
  };

  const searchPostcode = async () => {
    try {
      var myHeaders = new Headers();
      myHeaders.append("Cookie", "ASP.NET_SessionId=ed5gs5jk1vj2d3d0gxkvrezp");

      var requestOptions = {
        method: "GET",
        headers: myHeaders,
        redirect: "follow",
      };

      //here start loading animation
      dispatch({ type: SEARCH_POSTCODE_IN_PROGRESS, payload: true });

      //make the fetch
      const response = await fetch(
        `${process.env.GATSBY_ENTER_VOUCHER}Home/SearchPostcode?postcode=${state.personalDetails.postcode}`,
        requestOptions
      );

      //after fetch is complete stop the animation
      dispatch({ type: SEARCH_POSTCODE_IN_PROGRESS, payload: false });

      //process the response data
      const data = await response.json();

      const address = Object.values(data);

      dispatch({ type: SET_ADDRESS_DETAILS, payload: address });
    } catch (error) {
      console.log(error);
    }
  };

  const finEventsIdd = async () => {
    let myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("x-api-key", process.env.GATSBY_FIN_IDD_KEY);

    let raw = JSON.stringify({
      OrganisationLogo:
        MyTheme.externalLogo,
      OrganisationLogoAlt: MyTheme.pageTitle,
      AddressLine_1: "V12 b Merlin Park,",
      AddressLine_2: "Ringtail Road,",
      AddressLine_3: "Burscough, L40 8JY",
      FCANumber: "732530",
    });

    let requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };


    try {
      const response = await fetch(
        process.env.GATSBY_FIN_API_IDD,
        requestOptions
      );
      const data = await response.text();

      dispatch({ type: SET_FIN_IDD_RESULT, payload: data });

      if (typeof window !== "undefined") {
        sessionStorage.setItem("weddingIdd", data);
      }
    } catch (error) {
      dispatch({ type: FIN_IDD_ERROR });
    }
  };

  const getEventsQuote = async () => {
    dispatch({ type: GET_WEDDING_PRODUCTS_LOADING });

    let myHeaders = new Headers();
    myHeaders.append("Cookie", "ASP.NET_SessionId=vai1rjmn32ye02hxygceqjxj");

    let formdata = new FormData();
    let myData = {}

    myData.provider_id = process.env.GATSBY_WEDDING_PROVIDER_ID
    myData.wedding_date = state.weddingDetails.weddingDate
    myData.price = state.weddingDetails.totalCost
      ? state.weddingDetails.totalCost
      : 0
    myData.first_name = state.personalDetails.purchaser === true
      ? state.personalDetails.firstCeremonyParticipantForname
      : state.personalDetails.purchaserFirstname
    myData.last_name = state.personalDetails.purchaser === true
      ? state.personalDetails.firstCeremonyParticipantSurname
      : state.personalDetails.purchaserSurname
    myData.address1 = state.personalDetails.fullAddress
    myData.town = state.personalDetails.houseNumber
    myData.Postcode = state.personalDetails.postcode
    myData.email = state.personalDetails.emailAddress
    myData.date_of_birth = state.personalDetails.firstCeremonyParticipantDob
    myData.phone_number = state.personalDetails.telephoneNumber

    let myEligibility = {}
    if (state.weddingDetails.ceremonialSwords === true) {
      myEligibility.CeremonialSwords = '1'
    } else {
      myEligibility.CeremonialSwords = '0'
    }

    if (state.weddingDetails.covidCover === true) {
      myEligibility.CovidCover = '1'
    } else {
      myEligibility.CovidCover = '0'
    }

    if (state.weddingDetails.marqueeCoverAmount === "20000") {
      myEligibility.MarqueeCover = 'LTEQ20000'
    }
    else if (state.weddingDetails.marqueeCoverAmount === "50000") {
      myEligibility.MarqueeCover = 'GT20000'
    }

    if (state.weddingDetails.publicLiabilityLevel === 'couple only'
      || state.weddingDetails.publicLiabilityLevel === '') {
      myEligibility.PublicLiability = 'CoupleOnly'
    }
    else if (state.weddingDetails.publicLiabilityLevel === 'couple and guests') {
      myEligibility.PublicLiability = 'CoupleAndGuests'
    }
    
    if (state.weddingDetails.monetaryLiabilityCover === '2M'){
      myEligibility.PublicLiabilityValue = '£2M'
    }
    else if (state.weddingDetails.monetaryLiabilityCover === '5M') {
      myEligibility.PublicLiabilityValue = '£5M'
    }

    let eligibility = 0;
    if (eligibility == 0) {
      myData.eligibility = myEligibility;
    }

  
    formdata.append('data', JSON.stringify(myData))



    let requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: formdata,
      redirect: "follow",
    };

    try {
      const response = await fetch(
        `${process.env.GATSBY_ENTER_VOUCHER}ExternalQuote/GetEventsQuote`,
        requestOptions
      );
      const data = await response.json();

      dispatch({ type: GET_WEDDING_PRODUCTS, payload: data });
    } catch (error) {
      dispatch({ type: GET_WEDDING_PRODUCTS_ERROR });
    }
  };



  const addToCart = (item) => {
    dispatch({ type: ADD_TO_CART, payload: item });

  };

  const clearCart = () => {
    dispatch({ type: CLEAR_CART });
    dispatch({type: DELETE_STRIPE_CLIENT_SECRET});

  };

  //cand fac fetch ul la addresa fac un split dupa virgula si join ca sa eleinim postcode ul din string sau aleg address din context

  const changeStepIndex = (step, products) => {
    if (step < state.step && products.length !== 0) {
      dispatch({ type: UPDATE_PROGRESS_BAR_INDEX, payload: step });
    } else if (step > state.step && products.length !== 0) {
      dispatch({
        type: UPDATE_PROGRESS_BAR_INDEX,
        payload: step > 3 ? 3 : step,
      });
    }
  };
  const checkBankData = async () => {
    dispatch({ type: START_CHECK_VALIDATION });

    var myHeaders = new Headers();
    // myHeaders.append("Cookie", "ASP.NET_SessionId=ythit2zw2scv2se0vb2hqzqp");
    myHeaders.append("Cookie", "ASP.NET_SessionId=ed5gs5jk1vj2d3d0gxkvrezp");

    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    try {
      const response = await fetch(
        `${process.env.GATSBY_ENTER_VOUCHER}/Home/BankValidationSeach?accountNumber=${state.directDebitDetails.accountNo}&sortCode=${state.directDebitDetails.sortCode}`,
        requestOptions
      );

      const data = await response.json();

      if (data.code === "Failed") {
        dispatch({ type: FAILED_BANK_VALIDATION_REQUEST });
      } else if (data.code === "Success") {
        dispatch({ type: CHECK_BANK_DETAILS, payload: data });
      }
    } catch (error) {
      dispatch({ type: FAILED_BANK_VALIDATION_REQUEST });
    }
  };
  const handleDirectDebit = (e) => {
    let name = e.target.name;
    let value = e.target.value;
    
    if(name === "accountNo" || name === "sortCode"){

      value = value.replace(/[^\d,]/g,'')
      // if(e.target.validity.valid){

        if(name === "accountNo" && value.length !== 8){

          dispatch({type: ACCOUNT_NUMBER_ERROR});
        }
        else if(name == "sortCode" && value.length !== 6){

          dispatch({type: SORT_CODE_ERROR});

        }
        else{
          
          dispatch({type: ACCOUNT_NUMBER_VALID});
          dispatch({type: SORT_CODE_VALID});

        }
        
        dispatch({
          type: UPDATE_PERSONAL_FUNDING_DETAILS,
          payload: { name, value },
        });
      // }
    }
    else{
      dispatch({
        type: UPDATE_PERSONAL_FUNDING_DETAILS,
        payload: { name, value },
      });
    }
    
  };

  const handleDateOfBirth = (date) => {
    dispatch({ type: SET_BIRTHDAY, payload: date });
  };


  // change hash in the url

  // useEffect(() => {
  //   if (state.isStepChanged) {
  //     navigate(`${state.hash[state.step]}`);
  //   }
  // }, [state.step]);

  //get params from /init-basket

  // let queryId;
  // let queryQuote;

  // useEffect(() => {
  //   if (window.location.pathname === "/init-basket") {
  //     const queryParams = new URLSearchParams(window.location.search);
  //     queryId = queryParams.get("id");
  //     queryQuote = queryParams.get("quoteId");

  //     dispatch({ type: SET_QUERY_PARAMS, payload: { queryId, queryQuote } });
  //   }
  // }, [queryId, queryQuote]);

  const getQuoteDetails = async (quoteID, id) => {
    dispatch({ type: START_GET_QUOTE_DETAILS_LOADING });

    let requestOptions = {
      method: "GET",
      redirect: "follow",
    };

    try {
      const response = await fetch(
        `${process.env.GATSBY_ENTER_VOUCHER}ExternalQuote/GetQuoteDetails?quoteId=${quoteID}&ids=${id}`,
        requestOptions
      );
      const data = await response.json();
      const {
        quoteDetails: {
          ProductTypes,

          FirstName,
          Surname,
          EngineCC,
          HireReward,
          Mileage,
          RegNo,
          RegistrationDate,
          TyreSizeType,
          VehicleMake,
          Price,
          VehicleModel,
          VehiclePurchaseDate,
          Address1,
          Mobile,
          Town,
          Postcode,
          Email,
          DateOfBirth,

          EventDate,
        },
        products,
        quoteId,
        code,
      } = data;

   
      sessionStorage.setItem("quoteDetails", JSON.stringify({ ProductTypes,
          FirstName,
          Surname,
          Address1,
          Mobile,
          Town,
          Postcode,
          Email,
          EngineCC,
          HireReward,
          Mileage,
          RegNo,
          RegistrationDate,
          TyreSizeType,
          VehicleMake,
          Price,
          VehicleModel,
          VehiclePurchaseDate,
          DateOfBirth,
          EventDate,
          products,
          quoteId,
          code}));

      dispatch({
        type: SET_QUOTE_DETAILS,
        payload: {
          ProductTypes,
          FirstName,
          Surname,
          Address1,
          Mobile,
          Town,
          Postcode,
          Email,
          EngineCC,
          HireReward,
          Mileage,
          RegNo,
          RegistrationDate,
          TyreSizeType,
          VehicleMake,
          Price,
          VehicleModel,
          VehiclePurchaseDate,
          DateOfBirth,
          EventDate,
          products,
          quoteId,
          code,
        },
      });
    } catch (error) {
      dispatch({ type: QUOTE_DETAILS_ERROR })
    }
  };

  const selectTypeOfPayment = (event) => {
    // const radioValue = event.target.value;
    // console.log(event.target,".....type of payment")
    let radioValue;
    if (event.target.dataset.type){
      radioValue = event.target.dataset.type;
    } else {
      radioValue = event.target.value;
    }
    dispatch({ type: SET_TYPE_OF_PAYMENT, payload: radioValue });
  };

  const checkDemandandNeeds = async (quoteId, PPPId) => {
    dispatch({ type: GET_QUESTIONS_BEGIN });

    var myHeaders = new Headers();
    // myHeaders.append("Cookie", "ASP.NET_SessionId=j3e1skklwsjqiudyjopilixl");
    myHeaders.append("Cookie", "ASP.NET_SessionId=ed5gs5jk1vj2d3d0gxkvrezp");

    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    try {
      const response = await fetch(
        `${process.env.GATSBY_ENTER_VOUCHER}ExternalQuote/CheckDemandAndNeeds?quoteId=${quoteId}&ids=${PPPId}`,
        requestOptions
      );
      const data = await response.json();

      const { questions, premiumFundingDetails, code } = data;
      window.sessionStorage.setItem("premiumFundingDetailsInitBasket", JSON.stringify(data))
      dispatch({
        type: GET_QUESTIONS_SUCCESS,
        payload: { questions, premiumFundingDetails, code },
      });
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (
      state.needsAndDemandsCode === "Success" &&
      state.quoteDetails.code === "Success"
    ) {
      dispatch({ type: UPDATE_PROGRESS_BAR_INDEX, payload: JSON.parse(sessionStorage.getItem("currentStep")) });

    }
  }, [state.needsAndDemandsCode, state.quoteDetails.code]);

  const redirectToEventsCheckout = () => {

    if (
      state.needsAndDemandsCode === "Success" &&
      state.quoteDetails.code === "Success"
    ) {

      navigate("/wedding-checkout#legal")
      finEventsIdd();
    }
  };



  const changeWeddingIndexAfterRedirect = (index) => {
    dispatch({ type: UPDATE_STEP, payload: index });
  }

  const updateCartAfterRedirect = () => {
    dispatch({ type: UPDATE_CART })
  }

  const completeQuoteOrder = async () => {
    dispatch({ type: DELETE_EXISTING_STRIPE_DATA });
    dispatch({ type: COMPLETE_ORDER_BEGIN });
    const SelectedProducts = [{ "PPPId": state.cart.PPPId, "StartDate": '09/09/2023' }]
    let myHeaders = new Headers();
    // myHeaders.append("Cookie", "ASP.NET_SessionId=5tnbx0wsv0qzqaf4akfajpps");
    myHeaders.append("Cookie", "ASP.NET_SessionId=1vsvgysimyrtprm2q4qn42pr");


    let formdata = new FormData();

    if (state.typeOfPayment === "fullPayment") {
      formdata.append(
        "data",
        `{\n'QuoteId':'${state.quoteIDEvents}',\n'SelectedProducts':${JSON.stringify(
          SelectedProducts
        )},\n'PaymentType': '${state.typeOfPayment}',\n'PurchaseCode':'${state.purchaseCode
        }',\n'co2Emission':'${false}'}`
      );
    } else if (state.typeOfPayment === "directDebit") {
      formdata.append(
        "data",
        `{\n'QuoteId':'${state.quoteIDEvents}',\n'SelectedProducts':${JSON.stringify(
          SelectedProducts
        )},\n'DOB': '${state.dateOfBirth}',\n'PaymentType': '${state.typeOfPayment
        }',\n'TransactionId':'test',\n'AmountPaid': '${state.premiumFundingDetails.MinDepositAmount
        }',\n'AccountHolderFirstname': '${state.directDebitDetails.firstname
        }',\n'AccountHolderSurname':'${state.directDebitDetails.surname
        }',\n'AccountNumber':'${state.directDebitDetails.accountNo
        }',\n'SortCode':'${state.directDebitDetails.sortCode}',\n'BankName':'${state.directDebitDetails.bankName
        }',\n'PreferredPaymentDate':'${state.directDebitDetails.preferredPaymentDate
        }',\n'PurchaseCode':'${state.purchaseCode}',\n'co2Emission':'${false
        }'}`
      );
    }


    let requestOptions = {
      method: "POST",
      // headers: myHeaders,
      body: formdata,
      redirect: "follow",
    };


    try {
      const response = await fetch(
        `${process.env.GATSBY_ENTER_VOUCHER}ExternalQuote/CompleteQuoteOrder`,
        requestOptions
      );
      const data = await response.json();
      const { purchaseCode, code, stripeClientSecret, message } = data;

      sessionStorage.setItem("weddingPurchaseCode", purchaseCode);
      if (code === 'Success') {
        dispatch({
          type: GET_PURCHASE_CODE,
          payload: { purchaseCode, stripeClientSecret, code },
        });

        if(state.typeOfPayment === "directDebit" && state.premiumFundingDetails.MinDepositAmount === 0){
          
          dispatch({ type: INCREASE_WEDDING_STEP});
          sessionStorage.clear();
        }

      } else if (code === 'Failed') {
        dispatch({ type: COMPLETE_QUOTE_ERROR })
      }


      return code;
    } catch (error) {
      // dispatch({ type: GET_PRODUCTS_ERROR });
      console.log(error)
    }
  };




  const returnToProducts = () => {
    dispatch({ type: RETURN_TO_PRODUCTS })
  }

  useEffect(() => {
    if (state.step === 4) {
      sessionStorage.clear();
      localStorage.clear();
    }

    if (state.formStep === 0) {
      sessionStorage.removeItem("weddingPurchaseCode");
    }
  }, [state.step]);
  return (
    <ProductsContext.Provider
      value={{
        ...state,
        updateFilters,
        fetchFiltredProducts,
        getDate,
        searchPostcode,
        selectTypeOfPayment,
        increaseStep,
        finEventsIdd,
        decreaseStep,
        getEventsQuote,
        // finIdd,
        addToCart,
        clearCart,
        updateAdditionalOptions,
        updateEventDetails,
        updateEventToggle,
        updatePersonalDetails,
        changeStepIndex,
        getQuoteDetails,
        checkDemandandNeeds,
        handleDateOfBirth,
        redirectToEventsCheckout,
        checkBankData,
        changeWeddingIndexAfterRedirect,
        updateCartAfterRedirect,
        completeQuoteOrder,
        returnToProducts,
        handleDirectDebit


      }}
    >
      {children}
    </ProductsContext.Provider>
  );
};

export const useProductsContext = () => {
  return useContext(ProductsContext);
};
