import React, { useContext, useEffect, useReducer, useState } from "react";
import reducer from "../reducers/checkout-reducer";
import moment from "moment";
import { navigate } from "@reach/router";
import { navigate as gatsbyNavigate } from "gatsby";
import {
  UPDATE_FIELD,
  INCREASE_STEP,
  DECREASE_STEP,
  GET_ORIGIN,
  ADD_TO_CART,
  REMOVE_CART_ITEM,
  CLEAR_CART,
  COUNT_CART_TOTALS,
  GET_PRODUCTS_BEGIN,
  GET_PRODUCTS_ERROR,
  GET_PRODUCTS_SUCCESS,
  GET_REGISTRATION_DATE,
  GET_PURCHASE_DATE,
  GET_QUESTIONS_SUCCESS,
  GET_QUESTIONS_BEGIN,
  COMPLETE_ORDER_BEGIN,
  GET_PURCHASE_CODE,
  SET_FETCHED_VEHICLE_DETAILS,
  SET_SELECTED_PRODUCTS,
  CHANGE_SELECTED_PRODUCTS_DATE,
  SET_TYPE_OF_PAYMENT,
  SET_BIRTHDAY,
  SAVE_TOKEN,
  REFRESH_IDS,
  SEARCH_REG_NO_IN_PROGRESS,
  DELETE_EXISTING_STRIPE_DATA,
  RETURN_TO_PREVIOUS_STATE_FROM_PAYMENT,
  DISABLE_REACT_DATE_PICKER,
  CHECK_QUESTION,
  UNCHECK_ALL_QUESTIONS,
  SEARCH_POSTCODE_IN_PROGRESS,
  SET_ADDRESS_DETAILS,
  UPDATE_ADDRESS,
  CHOOSECO2,
  UPDATE_PERSONAL_FUNDING_DETAILS,
  UPDATE_HIRE_AND_REWARD_QUESTIONS,
  START_CHECK_VALIDATION,
  CHECK_BANK_DETAILS,
  SET_PAYMENT_DAY,
  FAILED_BANK_VALIDATION_REQUEST,
  SET_FIN_IDD_RESULT,
  SET_FIN_COMPLAINTS,
  SET_FIN_CANCELLATION,
  UPDATE_PROGRESS_BAR_INDEX,
  DELETE_STRIPE_CLIENT_SECRET,
  OPEN_MODAL,
  CLOSE_MODAL,
  INIT_BASKET_REDIRECT,
  CHANGE_STEP_VALUE
} from "../actions/actions";
import { MyTheme } from "../utils/informations";

const CheckoutContext = React.createContext();
export const CheckoutProvider = ({ children }) => {
  let PurchaseCode;
  let StoredVehicleDetails;
  let getQuoteResults = {};
  let yourDetailsStoredFormData = {};
  let vehicleDetailsStoredFormData = {};
  let storedCart = [];
  let storedHireAndReward = {};
  let storedIdd;
  let complaints;
  let cancellation;
  let checkoutStep;

  if (
    typeof window !== "undefined" &&
    window.sessionStorage.getItem("purchaseCode")
  ) {
    PurchaseCode = sessionStorage.getItem("purchaseCode");
  }

  if (
    typeof window !== "undefined" &&
    window.sessionStorage.getItem("formData")
  ) {
    StoredVehicleDetails = JSON.parse(sessionStorage.getItem("formData"));
  }

  if (
    typeof window !== "undefined" &&
    window.sessionStorage.getItem("fetchResult")
  ) {
    getQuoteResults = JSON.parse(sessionStorage.getItem("fetchResult"));
  }

  if (
    typeof window !== "undefined" &&
    window.sessionStorage.getItem("yourDetails")
  ) {
    yourDetailsStoredFormData = JSON.parse(
      sessionStorage.getItem("yourDetails")
    );
  }
  if (
    typeof window !== "undefined" &&
    window.sessionStorage.getItem("vehicleDetails")
  ) {
    vehicleDetailsStoredFormData = JSON.parse(
      sessionStorage.getItem("vehicleDetails")
    );
  }

  if (typeof window !== "undefined" && window.sessionStorage.getItem("cart")) {
    storedCart = JSON.parse(sessionStorage.getItem("cart"));
  }

  if (
    typeof window !== "undefined" &&
    window.sessionStorage.getItem("hireRewardQuestions")
  ) {
    storedHireAndReward = JSON.parse(
      sessionStorage.getItem("hireRewardQuestions")
    );
  }
  if (typeof window !== "undefined" && window.sessionStorage.getItem("idd")) {
    storedIdd = sessionStorage.getItem("idd");
  }
  if (
    typeof window !== "undefined" &&
    window.sessionStorage.getItem("complaints")
  ) {
    complaints = sessionStorage.getItem("complaints");
  }

  if (
    typeof window !== "undefined" &&
    window.sessionStorage.getItem("cancellation")
  ) {
    cancellation = sessionStorage.getItem("cancellation");
  }
  if (
    typeof window !== "undefined" &&
    window.sessionStorage.getItem("checkoutStep")
  ) {
    checkoutStep = JSON.parse(sessionStorage.getItem("checkoutStep"));
  }

  const initialState = {
    fetchResult: getQuoteResults.fetchResult ? getQuoteResults.fetchResult : [],
    formStep: checkoutStep ? checkoutStep : 0,
    isUndefined: false,
    details: {
      title: "Mr",
      firstname: yourDetailsStoredFormData.firstname
        ? yourDetailsStoredFormData.firstname
        : "",
      surname: yourDetailsStoredFormData.surname
        ? yourDetailsStoredFormData.surname
        : "",
      mobile: yourDetailsStoredFormData.mobile
        ? yourDetailsStoredFormData.mobile
        : "",
      email: yourDetailsStoredFormData.email
        ? yourDetailsStoredFormData.email
        : "",
      postcode: yourDetailsStoredFormData.postcode
        ? yourDetailsStoredFormData.postcode
        : "",
      address: yourDetailsStoredFormData.address
        ? yourDetailsStoredFormData.address
        : "",
      city: yourDetailsStoredFormData.city
        ? yourDetailsStoredFormData.city
        : "",
      regNo: vehicleDetailsStoredFormData.regNo
        ? vehicleDetailsStoredFormData.regNo
        : StoredVehicleDetails
        ? StoredVehicleDetails.regNo
        : "",
      vehicleMake: vehicleDetailsStoredFormData.vehicleMake
        ? vehicleDetailsStoredFormData.vehicleMake
        : "",
      vehicleModel: vehicleDetailsStoredFormData.vehicleModel
        ? vehicleDetailsStoredFormData.vehicleModel
        : "",
      categoryType: "Car",
      tyresType: vehicleDetailsStoredFormData.tyresType
        ? vehicleDetailsStoredFormData.tyresType
        : "",
      mileage: vehicleDetailsStoredFormData.mileage
        ? vehicleDetailsStoredFormData.mileage
        : StoredVehicleDetails
        ? StoredVehicleDetails.mileage
        : "",
      purchasePrice: vehicleDetailsStoredFormData.purchasePrice
        ? vehicleDetailsStoredFormData.purchasePrice
        : StoredVehicleDetails
        ? StoredVehicleDetails.vehicleValue
        : "",
      engineCc: vehicleDetailsStoredFormData.engineCc
        ? vehicleDetailsStoredFormData.engineCc
        : "",
      firstRegistrationDate: new Date(),
      dateOfPurchase: new Date(),
      firstRegistrationDateFormatted: moment(new Date()).format("DD/MM/YYYY"),
      dateOfPurchaseFormatted: moment(new Date()).format("DD/MM/YYYY"),
    },

    directDebitDetails: {
      firstname: "",
      surname: "",
      sortCode: "",
      accountNo: "",
      bankName: "",
      bankPostcode: "",
      bankAddress1: "",
      bankAddress2: "",
      bankTown: "",
      preferredPaymentDateUnformatted: new Date(),
      preferredPaymentDate: moment(new Date()).format("DD/MM/YYYY"),
    },
    directDebitLoading: false,
    directDebitError: false,
    bankValidationCode: "",
    fullAddress: "",
    // address:'  ',
    displayFirst: {
      targetOrigin: "",
      display: false,
    },
    hireRewardQuestions: {
      firstQuestion: storedHireAndReward.firstQuestion
        ? storedHireAndReward.firstQuestion
        : "false",
      secondQuestion: storedHireAndReward.secondQuestion
        ? storedHireAndReward.secondQuestion
        : "true",
      thirdQuestion: storedHireAndReward.thirdQuestion
        ? storedHireAndReward.thirdQuestion
        : "true",
    },
    co2Option: false,
    co2Emissions: {},
    purchaseError: false,
    cart: [],
    totalAmount: 0,
    totalItems: 0,
    cardIndex: 0,
    userDetails: {},
    quoteId: getQuoteResults.quoteId ? getQuoteResults.quoteId : "",
    loading: false,
    fetchError: false,
    isQuestionsLoading: false,
    ids: [],
    questions: [],
    checkedList: [],
    purchaseLoading: false,
    purchaseCode: PurchaseCode ? PurchaseCode : "test",
    fetchCode: getQuoteResults.fetchCode ? getQuoteResults.fetchCode : "", // gets fetch code
    searchRegNoInProgress: false,
    fetchPremiumFundingDetails: {},
    SelectedProductsUnformatted: [],
    SelectedProducts: [],
    typeOfPayment: "fullPayment",
    dateOfBirthUnformatted: new Date(),
    dateOfBirth: moment(new Date()).format("DD/MM/YYYY"),

    stripeClientSecret: "",
    stripeStatusCode: "",
    returnToPrevious: false, //if user returns to previous step and adds one more product, fetch again to completeOrder
    datePickerFlag: false, //disable  date picker
    searchPostcodeInProgress: false,
    addressArray: [],
    finIDDfetchResult: storedIdd ? storedIdd : "",
    finComplaints: complaints ? complaints : "",
    finCancellation: cancellation ? cancellation : "",
    hash: [
      "/checkout",
      "#vehicle-details",
      "#available-products",
      "#the-legal-bit",
      "#complete",
    ],
    isStepChanged: false,
    directDebitModal: false
  };
  const [state, dispatch] = useReducer(reducer, initialState);

  useEffect(() => {
    if (typeof window !== "undefined") {
      sessionStorage.setItem(
        "fetchResult",
        JSON.stringify({
          fetchResult: state.fetchResult,
          fetchCode: state.fetchCode,
          quoteId: state.quoteId,
        })
      );
    }
  }, [state.fetchResult]);

  useEffect(() => {
    if (typeof window !== "undefined") {
      sessionStorage.setItem("idd", state.finIDDfetchResult);
    }
  }, [state.finIDDfetchResult]);
  useEffect(() => {
    if (typeof window !== "undefined") {
      sessionStorage.setItem("complaints", state.finComplaints);
    }
  }, [state.finComplaints]);
  useEffect(() => {
    if (typeof window !== "undefined") {
      sessionStorage.setItem("cancellation", state.finCancellation);
    }
  }, [state.finCancellation]);

  useEffect(() => {
    if (typeof window !== "undefined") {
      sessionStorage.setItem("checkoutStep", state.formStep);
    }
  }, [state.formStep]);

  //functia care te redirectioneaza catre pagina cu produse daca iesi de pe checkout (avand GetQuote apelat si salvat in localStorage)  si vrei sa intri din nou

  let res;
  if (
    typeof window !== "undefined" &&
    window.localStorage.getItem("fetchResult")
  ) {
    res = JSON.parse(localStorage.getItem("fetchResult"));

    const origin = window.location.origin;
    const href = window.location.href;

    //used to redirect the user to the products when the user navigates to product page, but i added hash in the url and it wasn't needed anymore

    // if(origin !== href && res && state.formStep !== 2) {

    //   state.formStep = 3
    // }
  }

  if (typeof window !== `undefined`) {
    window.onbeforeunload = function () {
      localStorage.clear();
    };
  }

  const handleHireAndRewardQuestions = (e) => {
    let name = e.target.name;
    let value = e.target.value;
    dispatch({
      type: UPDATE_HIRE_AND_REWARD_QUESTIONS,
      payload: { name, value },
    });
  };

  const handleDirectDebit = (e) => {
    let name = e.target.name;
    let value = e.target.value;
    dispatch({
      type: UPDATE_PERSONAL_FUNDING_DETAILS,
      payload: { name, value },
    });
  };
  const updateFields = (e) => {
    let name = e.target.name;
    let value = e.target.value;
    dispatch({ type: UPDATE_FIELD, payload: { value, name } });
  };

  const updateAddress = (e) => {
    let value = e.target.value;

    const splitAddress = value.split(",");

    const city = splitAddress[splitAddress.length - 2];
    let address = "";
    let postcode = splitAddress[splitAddress.length - 1];

    for (let i = 0; i < splitAddress.length - 2; i++) {
      address += splitAddress[i] + " ";
    }

    dispatch({
      type: UPDATE_ADDRESS,
      payload: { value, city, address, postcode },
    });
  };

  let selectedProducts;
  selectedProducts = state.cart.map((cartItem) => ({
    Title: cartItem.Title,
    PPPId: cartItem.PPPId,
    StartDate: new Date(),
    Origin: cartItem.Origin,
    Price: cartItem.Price,
    months_of_cover: cartItem.months_of_cover,
  }));

  useEffect(() => {
    dispatch({ type: SET_SELECTED_PRODUCTS, payload: selectedProducts });
    dispatch({ type: REFRESH_IDS }); // used when i delete an item from the cart so refresh the ids array for another fetch with the currents ids
  }, [state.cart]);

  const handleDate = (date, id) => {
    dispatch({ type: CHANGE_SELECTED_PRODUCTS_DATE, payload: { date, id } });
  };

  const increaseStep = () => {
    dispatch({ type: INCREASE_STEP });
  };

  const decreaseStep = () => {
    dispatch({ type: DECREASE_STEP });
  };

  const getInsuranceOrigin = (e) => {
    const Origin = e.target.id;

    dispatch({ type: GET_ORIGIN, payload: Origin });
    sessionStorage.setItem("displayFirst", Origin);
  };

  const addToCart = (product) => {
    dispatch({ type: ADD_TO_CART, payload: { product } });
    // localStorage.removeItem('fetchResult')
  };

  const clearCart = () => {
    dispatch({ type: CLEAR_CART });
  };

  const finIdd = async () => {
    let myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("x-api-key", process.env.GATSBY_FIN_IDD_KEY);

    let raw = JSON.stringify({
      OrganisationLogo:
        MyTheme.externalLogo,
      OrganisationLogoAlt: MyTheme.pageTitle,
      AddressLine_1: "V12 b Merlin Park,",
      AddressLine_2: "Ringtail Road,",
      AddressLine_3: "Burscough, L40 8JY",
      FCANumber: "732530",
    });

    let requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    try {
      const response = await fetch(
        process.env.GATSBY_FIN_API_IDD,
        requestOptions
      );
      const data = await response.text();

      dispatch({ type: SET_FIN_IDD_RESULT, payload: data });
    } catch (error) {
      console.log(error);
    }
  };

  const complaintsProcedure = async () => {
    let myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("x-api-key", process.env.GATSBY_FIN_DEFAULT_KEY);

    let raw = JSON.stringify({
      OrganisationLogo:
        MyTheme.externalLogo,
      OrganisationLogoAlt: MyTheme.pageTitle,
      AddressLine_1: "V12 b Merlin Park,",
      AddressLine_2: "Ringtail Road,",
      AddressLine_3: "Burscough, L40 8JY",
      FCANumber: "732530",
    });

    let requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    try {
      const response = await fetch(
        process.env.GATSBY_FIN_COMPLAINTS_API,
        requestOptions
      );
      const data = await response.text();

      dispatch({ type: SET_FIN_COMPLAINTS, payload: data });
    } catch (error) {
      console.log(error);
    }
  };
  const cancellationAndClaimProcess = async () => {
    let myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("x-api-key", process.env.GATSBY_FIN_DEFAULT_KEY);

    let raw = JSON.stringify({
      OrganisationLogo:
        MyTheme.externalLogo,
      OrganisationLogoAlt: MyTheme.pageTitle,
      AddressLine_1: "V12 b Merlin Park,",
      AddressLine_2: "Ringtail Road,",
      AddressLine_3: "Burscough, L40 8JY",
      FCANumber: "732530",
    });

    let requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    try {
      const response = await fetch(
        process.env.GATSBY_FIN_CANCELLATION_API,
        requestOptions
      );
      const data = await response.text();

      dispatch({ type: SET_FIN_CANCELLATION, payload: data });
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    complaintsProcedure();
    cancellationAndClaimProcess();

    //   if(typeof window !== 'undefined' && window.sessionStorage.getItem('purchaseCode')) {
    //   PurchaseCode = sessionStorage.getItem('purchaseCode');
    // }

    // sessionStorage.setItem('purchaseCode', state.purchaseCode)
  }, []);

  const getQuote = async () => {
    dispatch({ type: GET_PRODUCTS_BEGIN });

    // finIdd()

    let myHeaders = new Headers();
    // myHeaders.append("Cookie", "ASP.NET_SessionId=5tnbx0wsv0qzqaf4akfajpps");
    myHeaders.append("Cookie", "ASP.NET_SessionId=ed5gs5jk1vj2d3d0gxkvrezp");

    let formdata = new FormData();

    formdata.append(
      "data",
      `{\n'IgnoreFilters':'false',\n'ProviderId':'${process.env.GATSBY_PROVIDER_ID}',\n'RegNo':'${state.details.regNo.toUpperCase()}',\n'Mileage':'${state.details.mileage}',\n'Price':'${state.details.purchasePrice}',\n'FirstName':'${state.details.firstname}',\n'Surname':'${state.details.surname}',\n'Address1':'${state.details.address}',\n'Email':'${state.details.email}',\n'Title':'${state.details.title}',\n'VehicleMake':'${state.details.vehicleMake}',\n'VehicleModel':'${state.details.vehicleModel}',\n'TyreSizeType':'${state.details.tyresType}',\n'EngineCC':'${state.details.engineCc}',\n'RegistrationDate':'${state.details.firstRegistrationDateFormatted}',\n'VehiclePurchaseDate':'${state.details.dateOfPurchaseFormatted}',\n'Town':'${state.details.city}',\n'Postcode':'${state.details.postcode.toUpperCase()}',\n'Mobile':'${state.details.mobile}',\n'HireReward':'${state.hireRewardQuestions.firstQuestion}'}`
    );

    let requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: formdata,
      redirect: "follow",
    };

    try {
      const response = await fetch(
        `${process.env.GATSBY_ENTER_VOUCHER}ExternalQuote/GetQuote`,
        requestOptions
      );
      const data = await response.json();
      const { products, quoteId, code } = data;
      let filteredDependingOnHireReward;

      if (
        state.hireRewardQuestions.secondQuestion === "false" &&
        state.hireRewardQuestions.thirdQuestion === "false"
      ) {
        filteredDependingOnHireReward = products.filter(
          (item) =>
            item.Origin !== "Finance Gap" && item.Origin !== "Return to Invoice"
        );
        // localStorage.setItem('fetchResult', JSON.stringify({filteredDependingOnHireReward}))

        // } else  if(state.hireRewardQuestions.secondQuestion === 'true' && state.hireRewardQuestions.thirdQuestion === 'true') {
        //  filteredDependingOnHireReward =  products.filter((item) => item.Origin !== "Finance Gap" && item.Origin !== "Return to Invoice")
        //   // localStorage.setItem('fetchResult', JSON.stringify({filteredDependingOnHireReward}))
      } else {
        filteredDependingOnHireReward = products;
      }

      dispatch({
        type: GET_PRODUCTS_SUCCESS,
        payload: { filteredDependingOnHireReward, quoteId, code },
      });

      // if(window.localStorage) {

      localStorage.setItem(
        "fetchResult",
        JSON.stringify({ filteredDependingOnHireReward })
      );
      // }
      //  sessionStorage.setItem('fetchResult', JSON.stringify({fetchResult:filteredDependingOnHireReward, fetchCode: code, quoteId:quoteId}))

      return code;
    } catch (error) {
      dispatch({ type: GET_PRODUCTS_ERROR });
    }
  };

  const checkDemandandNeeds = async () => {
    dispatch({ type: GET_QUESTIONS_BEGIN });

    var myHeaders = new Headers();
    // myHeaders.append("Cookie", "ASP.NET_SessionId=j3e1skklwsjqiudyjopilixl");
    myHeaders.append("Cookie", "ASP.NET_SessionId=ed5gs5jk1vj2d3d0gxkvrezp");

    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    let Ids = state.ids.join(",");
    let QuoteId = state.quoteId;

    try {
      const response = await fetch(
        `${process.env.GATSBY_ENTER_VOUCHER}ExternalQuote/CheckDemandAndNeeds?quoteId=${QuoteId}&ids=${Ids}`,
        requestOptions
      );
      const data = await response.json();
      const { questions, premiumFundingDetails, co2Emission } = data;

      const removeInvert = questions.filter((question) => !question.Invert);

      dispatch({
        type: GET_QUESTIONS_SUCCESS,
        payload: { removeInvert, premiumFundingDetails, co2Emission },
      });
    } catch (error) {
      dispatch({ type: GET_PRODUCTS_ERROR });
    }
  };

  const searchRegNo = async () => {
    try {
      var myHeaders = new Headers();
      // myHeaders.append("Cookie", "ASP.NET_SessionId=3sfpw5ykmlqtwu3pjbjhetu2");
      myHeaders.append("Cookie", "ASP.NET_SessionId=ed5gs5jk1vj2d3d0gxkvrezp");

      var requestOptions = {
        method: "GET",
        headers: myHeaders,
        redirect: "follow",
      };

      //here start loading animation
      dispatch({ type: SEARCH_REG_NO_IN_PROGRESS, payload: true });

      //make the fetch
      const response = await fetch(
        `${process.env.GATSBY_ENTER_VOUCHER}Home/SearchRegNo?regNo=${state.details.regNo.toUpperCase()}`,
        requestOptions
      );

      //after fetch is complete stop the animation
      dispatch({ type: SEARCH_REG_NO_IN_PROGRESS, payload: false });

      //process the response data
      const data = await response.json();
      const {
        VehicleMake,
        VehicleModel,
        AlloyWheelType,
        EngineCC,
        VehicleRegistrationDate,
      } = data;
      // VehicleRegistrationDate: "/Date(1351033200000)/"

      let dateSubstring = Number(
        VehicleRegistrationDate.substring(6, VehicleRegistrationDate.length - 2)
      );

      dispatch({
        type: SET_FETCHED_VEHICLE_DETAILS,
        payload: {
          VehicleMake,
          VehicleModel,
          AlloyWheelType,
          EngineCC,
          dateSubstring,
        },
      });
    } catch (error) {
      dispatch({ type: GET_PRODUCTS_ERROR });
    }
  };

  const searchPostcode = async () => {
    try {
      var myHeaders = new Headers();
      // myHeaders.append("Cookie", "ASP.NET_SessionId=3sfpw5ykmlqtwu3pjbjhetu2");
      myHeaders.append("Cookie", "ASP.NET_SessionId=ed5gs5jk1vj2d3d0gxkvrezp");

      var requestOptions = {
        method: "GET",
        headers: myHeaders,
        redirect: "follow",
      };

      //here start loading animation
      dispatch({ type: SEARCH_POSTCODE_IN_PROGRESS, payload: true });

      //make the fetch
      const response = await fetch(
        `${process.env.GATSBY_ENTER_VOUCHER}Home/SearchPostcode?postcode=${state.details.postcode.toUpperCase()}`,
        requestOptions
      );

      //after fetch is complete stop the animation
      dispatch({ type: SEARCH_POSTCODE_IN_PROGRESS, payload: false });

      //process the response data
      const data = await response.json();

      const address = Object.values(data);

      dispatch({ type: SET_ADDRESS_DETAILS, payload: address });
    } catch (error) {
      dispatch({ type: GET_PRODUCTS_ERROR });
    }
  };

  const saveStripeToken = (id) => {
    dispatch({ type: SAVE_TOKEN, payload: id });
  };

  const completeQuoteOrder = async () => {
    dispatch({ type: DELETE_EXISTING_STRIPE_DATA });
    dispatch({ type: COMPLETE_ORDER_BEGIN });

    let myHeaders = new Headers();
    // myHeaders.append("Cookie", "ASP.NET_SessionId=5tnbx0wsv0qzqaf4akfajpps");
    myHeaders.append("Cookie", "ASP.NET_SessionId=ed5gs5jk1vj2d3d0gxkvrezp");

    let formdata = new FormData();

    if (state.typeOfPayment === "fullPayment") {
      formdata.append(
        "data",
        `{\n'QuoteId':'${state.quoteId}',\n'SelectedProducts':${JSON.stringify(
          state.SelectedProducts
        )},\n'PaymentType': '${state.typeOfPayment}',\n'PurchaseCode':'${
          state.purchaseCode
        }',\n'co2Emission':'${state.co2Option}'}`
      );
    } else if (state.typeOfPayment === "directDebit") {
      formdata.append(
        "data",
        `{\n'QuoteId':'${state.quoteId}',\n'SelectedProducts':${JSON.stringify(
          state.SelectedProducts
        )},\n'DOB': '${state.dateOfBirth}',\n'PaymentType': '${
          state.typeOfPayment
        }',\n'TransactionId':'test',\n'AmountPaid': '${
          state.fetchPremiumFundingDetails.MinDepositAmount
        }',\n'AccountHolderFirstname': '${
          state.directDebitDetails.firstname
        }',\n'AccountHolderSurname':'${
          state.directDebitDetails.surname
        }',\n'AccountNumber':'${
          state.directDebitDetails.accountNo
        }',\n'SortCode':'${state.directDebitDetails.sortCode}',\n'BankName':'${
          state.directDebitDetails.bankName
        }',\n'PreferredPaymentDate':'${
          state.directDebitDetails.preferredPaymentDate
        }',\n'PurchaseCode':'${state.purchaseCode}',\n'co2Emission':'${
          state.co2Option
        }'}`
      );
    }

    let requestOptions = {
      method: "POST",
      // headers: myHeaders,
      body: formdata,
      redirect: "follow",
    };

    try {
      const response = await fetch(
        `${process.env.GATSBY_ENTER_VOUCHER}ExternalQuote/CompleteQuoteOrder`,
        requestOptions
      );
      const data = await response.json();
      const { purchaseCode, code, stripeClientSecret } = data;

      sessionStorage.setItem("purchaseCode", purchaseCode);
      dispatch({
        type: GET_PURCHASE_CODE,
        payload: { purchaseCode, stripeClientSecret, code },
      });

      return code;
    } catch (error) {
      dispatch({ type: GET_PRODUCTS_ERROR });
    }
  };

  const checkBankData = async () => {
    dispatch({ type: START_CHECK_VALIDATION });

    var myHeaders = new Headers();
    // myHeaders.append("Cookie", "ASP.NET_SessionId=ythit2zw2scv2se0vb2hqzqp");
    myHeaders.append("Cookie", "ASP.NET_SessionId=ed5gs5jk1vj2d3d0gxkvrezp");

    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    try {
      const response = await fetch(
        `${process.env.GATSBY_ENTER_VOUCHER}/Home/BankValidationSeach?accountNumber=${state.directDebitDetails.accountNo}&sortCode=${state.directDebitDetails.sortCode}`,
        requestOptions
      );

      const data = await response.json();

      if (data.code === "Failed") {
        dispatch({ type: FAILED_BANK_VALIDATION_REQUEST });
      } else if (data.code === "Success") {
        dispatch({ type: CHECK_BANK_DETAILS, payload: data });
      }
    } catch (error) {
      dispatch({ type: FAILED_BANK_VALIDATION_REQUEST });
    }
  };

  const removeItem = async (id) => {
    dispatch({ type: REMOVE_CART_ITEM, payload: id });
  };

  const getPurchaseDate = (date) => {
    dispatch({ type: GET_PURCHASE_DATE, payload: date });
  };

  const getRegistrationDate = (date) => {
    dispatch({ type: GET_REGISTRATION_DATE, payload: date });
  };

  const selectTypeOfPayment = (event) => {
    const radioValue = event.target.value;
    dispatch({ type: SET_TYPE_OF_PAYMENT, payload: radioValue });
  };

  const handleDateOfBirth = (date) => {
    dispatch({ type: SET_BIRTHDAY, payload: date });
  };
  const handlePreferredPaymentDay = (date) => {
    dispatch({ type: SET_PAYMENT_DAY, payload: date });
  };

  const handleReturnToPrevious = () => {
    dispatch({ type: RETURN_TO_PREVIOUS_STATE_FROM_PAYMENT });
  };

  const uncheckAll = () => {
    dispatch({ type: UNCHECK_ALL_QUESTIONS });
  };

  const toggleOption = (questions, id, checked) => {
    return questions.map((option) =>
      option.PPPIds === id ? { ...option, checked } : option
    );
  };

  const changeList = (PPPIds, checked) => {
    dispatch({
      type: CHECK_QUESTION,
      payload: toggleOption(state.checkedList, PPPIds, checked),
    });
  };

  const resetQuestions = () => {
    dispatch({ type: UNCHECK_ALL_QUESTIONS });
  };

  const deleteClientSecret = () => {
    dispatch({ type: DELETE_STRIPE_CLIENT_SECRET });
  };

  useEffect(() => {
    async function fetchAfterRemoveAnItem() {
      if (state.formStep === 4) {
        await checkDemandandNeeds();
        await completeQuoteOrder();
        uncheckAll();
      }
    }

    fetchAfterRemoveAnItem();
  }, [state.ids]);

  const chooseCo2 = (e) => {
    dispatch({ type: CHOOSECO2, payload: e.target.checked });
  };

  useEffect(() => {
    sessionStorage.setItem("cart", JSON.stringify(state.cart));
  }, [state.cart]);

  useEffect(() => {
    if (state.formStep === 4) {
      sessionStorage.clear();
      localStorage.clear();
    }

    if (state.formStep === 0) {
      sessionStorage.removeItem("purchaseCode");
    }
  }, [state.formStep]);

  const changeStepIndex = (step, products) => {
    if (step < state.formStep && products.length !== 0) {
      dispatch({ type: UPDATE_PROGRESS_BAR_INDEX, payload: step });
    } else if (step > state.formStep && products && products.length !== 0) {
      dispatch({
        type: UPDATE_PROGRESS_BAR_INDEX,
        payload: step > 3 ? 3 : step,
      });
    }
  };

  const openModal=() => {
    dispatch({type:OPEN_MODAL})
  }
  const closeModal=() => {
    dispatch({type:CLOSE_MODAL})
  }

  const redirectToVehiclesCheckout = () => {
    dispatch({type:CHANGE_STEP_VALUE, payload: 0})
    gatsbyNavigate('/checkout')
  }

  const initializeEligibilitiesData = () => {
    dispatch({type:INIT_BASKET_REDIRECT})
  }

  useEffect(() => {
    dispatch({ type: COUNT_CART_TOTALS });
    dispatch({ type: DISABLE_REACT_DATE_PICKER });
  }, [state.cart]);

  //added hash in the url

  useEffect(() => {
    if (state.isStepChanged) {
      navigate(`${state.hash[state.formStep]}`);
    }
  }, [state.formStep]);

  //state for init basket variables

   const [isRedirected, setIsRedirected] = useState( typeof window !== "undefined" &&
  window.sessionStorage.getItem("redirectFlag") ? JSON.parse(window.sessionStorage.getItem("redirectFlag")) : '')

  const [personalFundingDetailsInitBasket, setPersonalFundingDetailsInitBasket] = useState(typeof window !== "undefined" &&
  window.sessionStorage.getItem("premiumFundingDetailsInitBasket") ? JSON.parse(window.sessionStorage.getItem("premiumFundingDetailsInitBasket")) : '')


  useEffect(() => {

    if(typeof window !== 'undefined') {

     
      const theme = localStorage.getItem('currentTheme')

      const isRedirected = JSON.parse(window.sessionStorage.getItem("redirectFlag"))
      const personalDetails = JSON.parse(window.sessionStorage.getItem("premiumFundingDetailsInitBasket"))
        
      if(isRedirected && personalDetails) {
       dispatch({type:INIT_BASKET_REDIRECT})
      }
   
 

    }
  }, []);


  return (
    <CheckoutContext.Provider
      value={{
        ...state,
        addToCart,
        clearCart,
        increaseStep,
        decreaseStep,
        updateFields,
        getInsuranceOrigin,
        getQuote,
        getRegistrationDate,
        getPurchaseDate,
        checkDemandandNeeds,
        completeQuoteOrder,
        searchRegNo,
        handleDate,
        selectTypeOfPayment,
        handleDateOfBirth,
        saveStripeToken,
        removeItem,
        handleReturnToPrevious,
        changeList,
        resetQuestions,
        searchPostcode,
        updateAddress,
        chooseCo2,
        handleDirectDebit,
        handleHireAndRewardQuestions,
        checkBankData,
        handlePreferredPaymentDay,
        finIdd,
        changeStepIndex,
        deleteClientSecret,
        openModal,
        closeModal,
        redirectToVehiclesCheckout,
        initializeEligibilitiesData
      }}
    >
      {children}
    </CheckoutContext.Provider>
  );
};
export const useCheckoutContext = () => {
  return useContext(CheckoutContext);
};
//OY62XZL nr de inmatriculare
