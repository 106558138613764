
/* filter result array by the first item with a certain claim_limit and term */
export const findByClaimsAndMonth = (data, claims, months) => {
  let filteredData = []
  claims.forEach(claim => {
    months.forEach(month => {
      const itemFound = data.filter(
        e => e.claim_limit === claim && e.months_of_cover === month
      )[0]
      filteredData.push(itemFound)
    })
  })
  return filteredData
}
// data => api data from fetch
// claim => array of claim_limit
// term => array of months_of_cover
export const groupBy = (key, arr) =>
  arr.reduce((acc, val) => {
    const property = val[key]
    if (property in acc) {
      return { ...acc, [property]: acc[property].concat(val) }
    }
    return { ...acc, [property]: [val] }
  }, {})



export const groupByCodeTemplate = (array, key) => {
  let grouped = {};
  if(array) {
      for (let i = 0; i < array.length; i++) {
   
    let p = (array[i][key]).slice(0, array[i][key].length - 2);
    if (!grouped[p]) { 
      grouped[p] = []; 
    }

    grouped[p].push(array[i]);
  }
  }

  return grouped;
}



// acc-acumulator
// val- valoarea curenta
// reduce will iterate through every item of the array, the first time, acc = []
//check postcode
export function checkPostcode(postcode) {
  const regex =
    /^([Gg][Ii][Rr] 0[Aa]{2})|((([A-Za-z][0-9]{1,2})|(([A-Za-z][A-Ha-hJ-Yj-y][0-9]{1,2})|(([A-Za-z][0-9][A-Za-z])|([A-Za-z][A-Ha-hJ-Yj-y][0-9]?[A-Za-z])))) [0-9][A-Za-z]{2})$/
  //  const regexp = /^[A-Z]{1,2}[0-9RCHNQ][0-9A-Z]?\s?[0-9][ABD-HJLNP-UW-Z]{2}$|^[A-Z]{2}-?[0-9]{4}$/
  if (regex.test(postcode)) {
    return true
  } else {
    return false
  }
}

export function validateEmail(email) {
  const regex =
    /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  return regex.test(email)
}



export function validateMobile(mobile) {
 
 if(mobile[0] == 0 && mobile.length <= 11) {
   return true
 } else {
   return false
 }
}
// export function validateMobile(mobile) {
//   const regex = /^(?:\W*\d){11}\W*$/
//   return regex.test(mobile)
// }
//07846444551


export function groupArray(array) {
  let grouped = Object.values(
       array.reduce((groupedArray, item) => {
          const origin = item.Origin
            if(groupedArray[origin] === null) {
            groupedArray[origin] = []
          }
          groupedArray[origin].push(item)

        
           return groupedArray
        }, {})
    );

    return grouped

}



//group array of objects depending on the origin for vehicle checkout

export function groupByOrigin(array) {
      const OriginMapper = {
      "MBI" : 'mbi',
      "Alloy Wheel": 'alloy',
      "Cosmetic Repair" : 'cosmetic',
      "Tyre": 'tyre',
      "Finance Gap": 'gap', 
      "Return to Invoice": 'gap',
      "Excess":'excess'
} 

  const dataGroupedByOriginMapper = {}

  const otherItemsListName = "others";

  array.forEach(item => {
      const { Origin } = item;
      const listName = Origin in OriginMapper ? OriginMapper[Origin] : otherItemsListName;

      if (listName in dataGroupedByOriginMapper && dataGroupedByOriginMapper[listName]) {
          dataGroupedByOriginMapper[listName].push(item);
      }
      else {
          dataGroupedByOriginMapper[listName] = [item];
      }
  })
  return dataGroupedByOriginMapper
}




//http://localhost:9000/sitemap/sitemap-index.xml  