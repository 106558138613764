import {
  GET_PRODUCTS_BEGIN,
  GET_PRODUCTS_SUCCESS,
  GET_PRODUCTS_ERROR,
  UPDATE_FILTERS,
  DELETE_STRIPE_CLIENT_SECRET,
  SEARCH,
  GET_QUOTE_ID,
  FILTER_PRODUCT_SUCCESS,
  SET_EVENT_DETAILS,
  UPDATE_PERSONAL_FUNDING_DETAILS,
  SET_DATE,
  INCREASE_WEDDING_STEP,
  SEARCH_POSTCODE_IN_PROGRESS,
  SET_TYPE_OF_PAYMENT,
  SET_ADDRESS_DETAILS,
  FIN_IDD_LOADING,
  FIN_IDD_ERROR,
  SET_FIN_IDD_RESULT,
  DECREASE_WEDDING_STEP,
  GET_WEDDING_PRODUCTS,
  GET_WEDDING_PRODUCTS_LOADING,
  GET_WEDDING_PRODUCTS_ERROR,
  ADD_TO_CART,
  CLEAR_CART,
  UPDATE_EVENT_DETAILS,
  UPDATE_PERSONAL_DETAILS,
  UPDATE_ADDITIONAL_OPTIONS,
  UPDATE_CART,
  SET_FIN_COMPLAINTS,
  SET_FIN_CANCELLATION,
  UPDATE_PROGRESS_BAR_INDEX,
  START_CHECK_VALIDATION,
  SET_QUERY_PARAMS,
  START_GET_QUOTE_DETAILS_LOADING,
  SET_BIRTHDAY,
  GET_QUESTIONS_BEGIN,
  GET_QUESTIONS_SUCCESS,
  SET_QUOTE_DETAILS,
  FAILED_BANK_VALIDATION_REQUEST,
  CHANGE_CHECKOUT_STEP,
  UPDATE_INIT_VALUES,
  UPDATE_REDIRECT_FLAG,
  GET_PRODUCTS_FROM_SESSION,
  UPDATE_STEP,
  DELETE_EXISTING_STRIPE_DATA,
  COMPLETE_ORDER_BEGIN,
  GET_PURCHASE_CODE,
  COMPLETE_QUOTE_ERROR,
  RETURN_TO_PRODUCTS,
  QUOTE_DETAILS_ERROR,
  SET_INIT_BASKET_QUERY_PARAMS,
  ACCOUNT_NUMBER_ERROR,
  ACCOUNT_NUMBER_VALID,
  SORT_CODE_ERROR,
  SORT_CODE_VALID

} from "../actions/actions";
import moment from "moment";
import { groupBy, groupByCodeTemplate } from "../utils/custom-functions";

const products_reducer = (state, action) => {
  if (action.type === GET_PRODUCTS_BEGIN) {
    return { ...state, products_loading: true, products_error: false };
  }

  if (action.type === GET_PRODUCTS_SUCCESS) {
    return {
      ...state,
      products_loading: false,
      products: action.payload,
      products_error: false,
    };
  }

  if (action.type === FILTER_PRODUCT_SUCCESS) {
    return { ...state, successResponse: true, search: true };
  }

  if (action.type === GET_PRODUCTS_ERROR) {
    return {
      ...state,
      products_loading: false,
      products_error: true,
      search: false,
    };
  }

  if (action.type === GET_QUOTE_ID) {
    return { ...state, quoteID: action.payload };
  }

  if (action.type === UPDATE_FILTERS) {
    const { value, name } = action.payload;

    return {
      ...state,
      filters: { ...state.filters, [name]: value },
      weddingDetails: { ...state.weddingDetails, [name]: value },
      personalDetails: { ...state.personalDetails, [name]: value },
      additionalOptions: { ...state.additionalOptions, [name]: value },
    };
  }
  if(action.type === SET_BIRTHDAY) {
    
    return {...state, dateOfBirthUnformatted: action.payload, dateOfBirth:  moment(action.payload).format('DD/MM/YYYY')}
  }
  if (action.type === UPDATE_ADDITIONAL_OPTIONS) {
    const { value, name } = action.payload;

    return {
      ...state,
      additionalOptions: { ...state.additionalOptions, [name]: value },
    };
  }

  if(action.type === DELETE_STRIPE_CLIENT_SECRET){

    return { ...state, stripeClientSecret: "", purchaseCode: ""};
  }
  if (action.type === UPDATE_PERSONAL_DETAILS) {
    const { value, name } = action.payload;

    // if(typeof window !== 'undefined') {
    //   sessionStorage.setItem('weddingPersonalDetailss', JSON.stringify(state.personalDetails))
    // }

    return {
      ...state,
      personalDetails: { ...state.personalDetails, [name]: value },
    };
  }
  if (action.type === UPDATE_EVENT_DETAILS) {
    const { value, name } = action.payload;

    return {
      ...state,
      weddingDetails: { ...state.weddingDetails, [name]: value },
    };
  }

  if (action.type === SEARCH) {
    return { ...state, search: true };
  }

  /// wedding logic

  if (action.type === SET_DATE) {
    const { date, id } = action.payload;
    return {
      ...state,
      weddingDetails: { ...state.weddingDetails, [id]: date },
      personalDetails: { ...state.personalDetails, [id]: date },
    };
  }

  if(action.type === ACCOUNT_NUMBER_ERROR){

    return {...state, accountNoError: true}
  }
  if(action.type === ACCOUNT_NUMBER_VALID){
    
    return {...state, accountNoError: false}
  }
  if(action.type === SORT_CODE_ERROR){
    
    return {...state, sortCodeError: true}
  }
  if(action.type === SORT_CODE_VALID){

    return {...state, sortCodeError: false}
  }
  if (action.type === INCREASE_WEDDING_STEP) {
    return { ...state, step: state.step + 1, isStepChanged: true };
  }
  if (action.type === DECREASE_WEDDING_STEP) {
    return {
      ...state,
      step: state.step > 0 ? state.step - 1 : 0,
      isStepChanged: true,
    };
  }
  //console.log(state.step, "wedding step")

  if (action.type === SEARCH_POSTCODE_IN_PROGRESS) {
    return { ...state, searchPostcodeInProgress: action.payload };
  }
  if (action.type === SET_ADDRESS_DETAILS) {
    return { ...state, addressArray: action.payload };
  }
  if(action.type ===  SET_FIN_IDD_RESULT) {
    return {...state, finIDDfetchResult: action.payload}
  }
  if(action.type ===  SET_FIN_COMPLAINTS) {
    return {...state, finComplaints: action.payload}
  }
  if(action.type ===  SET_FIN_CANCELLATION) {
    return {...state, finCancellation: action.payload}
  }
  if (action.type === GET_WEDDING_PRODUCTS_LOADING) {
    return { ...state, weddingProductsLoading: true };
  }

  if (action.type === GET_WEDDING_PRODUCTS) {
    let products = action.payload.products;

    let filterLibilityAndCouple = action.payload.products;
    
    //filter the array depending on liability cover and level

    if (
      state.weddingDetails.publicLiabilityCover === "true" &&
      action.payload.code !== "Failed"
    ) {

      let filterLiability = filterLibilityAndCouple.filter(
        (item) => item.Title.toLowerCase().includes("public liability")
      );
     
      if (state.weddingDetails.publicLiabilityLevel === "couple only" || state.weddingDetails.publicLiabilityLevel === "") { //default to couples only
       
        filterLibilityAndCouple = filterLibilityAndCouple.filter((item) =>
          item.Title.toLowerCase().includes("couple only")
        );
      } else if (
        state.weddingDetails.publicLiabilityLevel === "couple and guests"
      ) {
        
        filterLibilityAndCouple = filterLibilityAndCouple.filter((item) =>{
         return item.Title.toLowerCase().includes("couple and guests")}
        );
       
      }


    }

    let filterAdditionalOptions;

    if (
      state.weddingDetails.marqueeCoverAmount === "20000" &&
      !(state.weddingDetails.ceremonialSwords === true)
    ) {
      filterAdditionalOptions = filterLibilityAndCouple.filter((item) =>
        item.CodeTemplate.endsWith("01")
      );
    } else if (
      state.weddingDetails.marqueeCoverAmount === "20000" &&
      state.weddingDetails.ceremonialSwords === true
    ) {
      filterAdditionalOptions = filterLibilityAndCouple.filter((item) =>
        item.CodeTemplate.endsWith("11")
      );
    } else if (
      state.weddingDetails.marqueeCoverAmount === "50000" &&
      !(state.weddingDetails.ceremonialSwords === true)
    ) {
      filterAdditionalOptions = filterLibilityAndCouple.filter((item) =>
        item.CodeTemplate.endsWith("02")
      );
    } else if (
      state.weddingDetails.marqueeCoverAmount === "50000" &&
      state.weddingDetails.ceremonialSwords === true
    ) {
      filterAdditionalOptions = filterLibilityAndCouple.filter((item) =>
        item.CodeTemplate.endsWith("12")
      );
    } else if (
      state.weddingDetails.marqueeCoverAmount === "" &&
      state.weddingDetails.ceremonialSwords === true
    ) {
      filterAdditionalOptions = filterLibilityAndCouple.filter((item) =>
        item.CodeTemplate.endsWith("10")
      );
    } else if (
      state.weddingDetails.marqueeCoverAmount === false &&
      state.weddingDetails.ceremonialSwords === false
    ) {
      filterAdditionalOptions = filterLibilityAndCouple.filter((item) =>
        item.CodeTemplate.endsWith("00")
      );
    }

    // let filtredProducts =
    //   state.weddingDetails.publicLiabilityLevel === "couple only"
    //     ? filterAdditionalOptions.filter((item) =>
    //         item.Title.toLowerCase().includes("couple only")
    //       )
    //     : filterAdditionalOptions;
    let filtredProducts = filterLibilityAndCouple;

    if (typeof window !== "undefined") {
      sessionStorage.setItem(
        "weddingProducts",
        JSON.stringify(groupByCodeTemplate(filtredProducts, "CodeTemplate"))
      );
    }
    if (typeof window !== "undefined") {
      sessionStorage.setItem(
        "weddingQuote",
        action.payload.quoteId
      );
    }

    return {
      ...state,
      weddingProducts: groupByCodeTemplate(filtredProducts, "CodeTemplate"),
      quoteIDEvents: action.payload.quoteId,
      weddingProductsLoading: false,
      weddingProductsError: false,
    };
  }
  if(action.type === SET_TYPE_OF_PAYMENT) {

    return {...state, typeOfPayment: action.payload, selectTypeOfPaymentFlag:true}
  }
  if (action.type === GET_WEDDING_PRODUCTS_ERROR) {
    return { ...state, weddingProductsError: true };
  }

  if (action.type === ADD_TO_CART) {
    const product = action.payload;
    return {
      ...state,
      cart: product,
      selectedProductCodeTemplate: product.CodeTemplate.slice(
        0,
        product.CodeTemplate.length - 2
      ),
    };

    //functionality for adding more than one insurance

    // const tempItem = state.cart.find((cartItem) => cartItem.ProducGroupId === product.ProducGroupId)
    // if (tempItem) {
    //   const tempCart = state.cart.map((cartItem) => {
    //     if (cartItem.ProducGroupId === product.ProducGroupId) {

    //       return { ...cartItem }
    //     } else {
    //       return cartItem
    //     }
    //   })
    //   return { ...state, cart: tempCart }
    // } else {

    //   return { ...state, cart: [...state.cart, product] }
    // }
  }

  if (action.type === CLEAR_CART) {
    return { ...state, cart: "" };
  }
  if (action.type === UPDATE_PROGRESS_BAR_INDEX) {
    return { ...state, step: action.payload };
  }

  // init basket

  if (action.type === SET_QUERY_PARAMS) {
    return {
      ...state,
      ids: action.payload.queryId,
      quoteIdInitBasket: action.payload.queryQuote,
      quoteIDEvents: action.payload.queryQuote
    };
  }

  if (action.type === START_GET_QUOTE_DETAILS_LOADING) {
    return {
      ...state,
      getQuoteDetailsLoading: true,
    };
  }
  if (action.type === SET_INIT_BASKET_QUERY_PARAMS) {
    return {
      ...state,
      // quoteIdInitBasket:action.payload.quoteId,
      // ids:action.payload.id
    };
  }
  if (action.type === SET_QUOTE_DETAILS) {
    const {
      ProductTypes,
      FirstName,
      Surname,
      Address1,
      Mobile,
      Town,
      EngineCC,
      HireReward,
      Mileage,
      RegNo,
      RegistrationDate,
      TyreSizeType,
      VehicleMake,
      Price,
      VehicleModel,
      VehiclePurchaseDate,
      Postcode,
      Email,
      DateOfBirth,
      EventDate,
      products,
      quoteId,
      code,
    } = action.payload;

    return {
      ...state,
      testQuoteDetails: action.payload,
      getQuoteDetailsLoading: false,
      quoteIDEvents: quoteId,
      quoteDetails: {
        productType: ProductTypes,
        quoteId: quoteId,
        firstname: FirstName,
        surname: Surname,
        address: Address1,
        mobile: Mobile,
        town: Town,
        engineCC: EngineCC,
        hirereward:HireReward,
        mileage:Mileage,
        regno: RegNo,
        registrationdate: RegistrationDate,
        tyresize:TyreSizeType,
        vehiclemake:VehicleMake,
        price:Price,
        vehicleModel:VehicleModel,
        vehiclePurchaseDate:VehiclePurchaseDate,
        postcode: Postcode,
        email: Email,
        dob: DateOfBirth,
        eventDate: EventDate,
        products: products,
        code: code,
      },
    };
  }

  if(action.type === QUOTE_DETAILS_ERROR) {
    return {...state,  getQuoteDetailsLoading: false, quoteIdError:true}
  }

  if(action.type ===  START_CHECK_VALIDATION) {
    
    return {...state, directDebitLoading: true }
  }
  if(action.type === FAILED_BANK_VALIDATION_REQUEST) {

    return {...state, directDebitLoading:false, directDebitError:true}
  }
  //check demands and needs

  if (action.type === GET_QUESTIONS_BEGIN) {
    return { ...state, isQuestionsLoading: true };
  }

  if (action.type === GET_QUESTIONS_SUCCESS) {
    const { questions, premiumFundingDetails, code } = action.payload;

    return {
      ...state,
      questions: questions,
      premiumFundingDetails: premiumFundingDetails,
      isQuestionsLoading: false,
      needsAndDemandsCode: code,
    };
  }

  if (action.type === UPDATE_INIT_VALUES) {
    let quoteDetailsStored;
    let storedIdd;
    let storedWeddingProducts;
    if (
      typeof window !== "undefined" &&
      window.sessionStorage.getItem("quoteDetails")
    ) {
      quoteDetailsStored = JSON.parse(sessionStorage.getItem("quoteDetails"));
    }

    if (
      typeof window !== "undefined" &&
      window.sessionStorage.getItem("weddingIdd")
    ) {
      storedIdd = sessionStorage.getItem("weddingIdd");
    }
    if (
      typeof window !== "undefined" &&
      window.sessionStorage.getItem("weddingProducts")
    ) {
      storedWeddingProducts = JSON.parse(
        sessionStorage.getItem("weddingProducts")
      );
    }

    return {
      ...state,
      cart: quoteDetailsStored?.products[0],
      // step: state.isRedirected ?  3 : 0,
      // weddingProducts: storedWeddingProducts,
      finIddResult: storedIdd,
      weddingDetails: {
        ceremonyType: "marriage",
        location: "true",
        weddingDate: quoteDetailsStored?.EventDate || new Date(),
        reception: "false",
        ceremonyAndReception: "false",
        // receptionDate:  new Date(),

        publicLiabilityCover: "true",
        publicLiabilityLevel: "couple only",
        marqueeCover: "true",

        marqueeCoverAmount: "20000",
        ceremonialSwords: "false",
      },
      personalDetails: {
        firstCeremonyParticipantForname: quoteDetailsStored?.firstname,
        firstCeremonyParticipantSurname: quoteDetailsStored?.surname,
        firstCeremonyParticipantDob: new Date(),
        firstParticipantUkResidentAndRegistered: "false",
        secondCeremonyParticipantsFirstname: "test",
        secondCeremonyParticipantsSurname: "test",
        secondCeremonyParticipantsDob: new Date(),
        secondParticipantUkResidentAndRegistered: "false",
        purchaser: "test",
        relationWithParticipants: "test",
        purchaserFirstname: quoteDetailsStored?.firstname,
        purchaserSurname: quoteDetailsStored?.surname,
        purchaserDob: new Date(),
        houseNumber: "test",
        postcode: "test",
        fullAddress: "test",
        address: "tst",
        telephoneNumber: "test",
        emailAddress: quoteDetailsStored?.email,
      },
    };
  }


  if (action.type === UPDATE_REDIRECT_FLAG) {
    return {
      ...state,
      isRedirected: true,
    };
  }
  if (action.type === UPDATE_STEP) {
    return {
      ...state,
      step:action.payload
    };
  }

  if (action.type === UPDATE_CART) {

    let quoteDetailsStored;
    if (
      typeof window !== "undefined" &&
      window.sessionStorage.getItem("quoteDetails")
    ) {
      quoteDetailsStored = JSON.parse(sessionStorage.getItem("quoteDetails"));
    }

    return {
      ...state,
      cart: quoteDetailsStored?.products[0],
      quoteIDEvents:quoteDetailsStored?.quoteId
    };
  }


  if (action.type === GET_PRODUCTS_FROM_SESSION) {
    let storedProducts
      if (typeof window !== "undefined" && window.sessionStorage.getItem("weddingProducts")) {
    storedProducts = JSON.parse(window.sessionStorage.getItem("weddingProducts"))
  }

    return {
      ...state,
      weddingProducts:storedProducts, 
      // isRedirected:false
    };
  }


  //stripe payment

    if(action.type === DELETE_EXISTING_STRIPE_DATA) {
    return {...state, stripeTokenId:'', stripeStatusCode:''}
  }

  if(action.type === COMPLETE_ORDER_BEGIN) {
    return {...state, purchaseLoading: true,  completeQuoteError: false}
  }

  if(action.type === UPDATE_PERSONAL_FUNDING_DETAILS) {
    const {value, name} = action.payload
    return {...state,directDebitDetails:{...state.directDebitDetails, [name]:value} }
  }

   if(action.type === GET_PURCHASE_CODE) {
    const {purchaseCode, code, stripeClientSecret} = action.payload
    return {...state, purchaseCode: purchaseCode, stripeStatusCode:code, stripeClientSecret: stripeClientSecret, datePickerFlag:true, purchaseLoading: false,
     completeQuoteError: false}
  }
   if(action.type === COMPLETE_QUOTE_ERROR) {
   
    return {...state, completeQuoteError: true, purchaseLoading: false}
  }
   if(action.type === RETURN_TO_PRODUCTS) {

    if(state.quoteIDEvents) {
      return {...state, step:1, stripeClientSecret:'',purchaseCode:'', quoteIdInitBasket:'', ids:'', cart:''}
    } else {

      return {...state,step: state.step - 1, stripeClientSecret:'',purchaseCode:'', cart:'' }
    }
  }

  throw new Error(`No Matching "${action.type}" - action type`);
};

export default products_reducer;
